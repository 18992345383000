import { Box, Grid } from '@mui/material'
import React from 'react'
import CardOne from './CardOne';
import ExtensionIcon from '@mui/icons-material/Extension';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TimelineIcon from "@mui/icons-material/Timeline";
import { siyah } from '../config/colors';

const AboutCard = () => {
    const array = [
        { icon: <ExtensionIcon />, title:"enhanced gaming",texts:"elevate your gaming adventure with the GAU token. unlock exclusive in-game features, rewards, and benefits that redefine your gaming experience." },
        { icon: <TimelineIcon /> ,title:"curated experiences", texts:"explore a carefully curated selection of gaming products and services from gaulaxy’s partners, including gamer arena. immerse yourself in cutting-edge entertainment seamlessly integrated with the GAU token."},
        { icon: <LocalLibraryIcon />,title:"learn", texts:"gaulaxy isn't just about playing; it's about learning. engage in tutorials, content, and interactive experiences that help you understand and utilize the transformative power of digital currencies, particularly the GAU token." },
      ];
  return (
    <Box sx={{ backgroundColor: siyah,}}>
    
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
         
        >
          {array.map((item, index) => (
            <Grid
              item
              key={index}
              sm={4}
              sx={{ justifyContent: "center", display: "flex" }}
            >
              <CardOne icon={item.icon} texts={item.texts} title={item.title} />
            </Grid>
          ))}
        </Grid>
     
  </Box>
  )
}

export default AboutCard