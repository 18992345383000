import React from "react";
import TitleOne from "./TitleOne";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import img3 from "../assets/9.png";
import img4 from "../assets/10.png";
import img5 from "../assets/12.png";
import CommentIcon from "@mui/icons-material/Comment";
import { beyaz, siyah, siyahimsi } from "../config/colors";
const Testimonials = () => {
  const arrayT = [
    {
      images: img3,
      name: "berkay hfo",
      desc: `"gaulaxy has brilliantly harnessed the power of web3 and digital currencies to redefine the gaming landscape. it’s not just a platform; it’s a visionary space where the convergence of gaming and decentralized technologies is driving unparalleled growth in the digital currency realm."`,
    },
    {
      images: img4,
      name: "kerim yılmaz, gamer arena ceo",
      desc: `"the gaulaxy platform is incredibly user-friendly, making my experience seamless and enjoyable. the wealth of opportunities within gaulaxy labs assures me that it’s not just a platfor but a dynamic space with endless potential for growth."`,
    },
    {
      images: img5,
      name: "alexis t.",
      desc: `"gaulaxy’s ingenious integration of ai and gaming features showcases a visionary approach. it’s evident that the fusion of these technologies has the potential to birth not only captivating gaming experiences but also pave the way for the creation of incredibly useful products within the digital landscape."`,
    },
  ];
  return (
    <>
      <TitleOne title="testimonials" fw="bold" />

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ pb: 3, pt: 6, backgroundColor: siyah, px: 1 }}
      >
        {arrayT.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              sx={{
                width: "60%",
                minWidth: 350,
                borderRadius: 3,
                boxShadow: "0 8px 16px rgba(32,40,47, 0.2)",
                my: "5%",
                background: siyahimsi,
                minHeight: "480px",
              }}
            >
              <CardContent
                sx={{
                  justifyContent: "flex-start",
                  display: "flex",
                  p: 0,
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: "black",
                    width: "40px",
                    height: "40px",
                    marginLeft: 1,
                    marginTop: 1,
                    background: beyaz,
                  }}
                >
                  <CommentIcon sx={{ color: siyahimsi }} />
                </Avatar>
              </CardContent>
              <CardContent
                sx={{ justifyContent: "center", display: "flex", py: "8%" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "18px",
                    color: beyaz,
                    fontStyle: "italic",
                  }}
                >
                  {item.desc}
                </Typography>
              </CardContent>
              <CardContent sx={{ justifyContent: "center", display: "flex" }}>
                <Avatar sx={{ width: "75px", height: "75px" }}>
                  <img
                    src={item.images}
                    alt="Avatar Image"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  />
                </Avatar>
              </CardContent>
              <CardContent sx={{ justifyContent: "center", display: "flex" }}>
                {" "}
                <Typography
                  sx={{
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "18px",
                    color: beyaz,
                  }}
                >
                  {item.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Testimonials;
