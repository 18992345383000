import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { beyaz, koyugri } from "../config/colors";
import DetailText from "./DetailText";

const DetailOne = ({ totalStaked }) => {
  const formattedTotalStaked = (+totalStaked).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Manrope, sans-serif",
          fontSize: "20px",
          color: beyaz,
          py: 1,
          fontWeight: "bold",
        }}
      >
        overview
      </Typography>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={4} xs={12}>
          <Card
            sx={{ height: "90px", backgroundColor: koyugri, borderRadius: 4 }}
          >
            <CardContent sx={{ pb: 0.2 }}>
              <Typography
                sx={{
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "14px",
                }}
              >
                total staked
              </Typography>
            </CardContent>
            <CardContent sx={{ py: 0 }}>
              <Typography
                sx={{
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                {formattedTotalStaked} GAU
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card
            sx={{ height: "90px", backgroundColor: koyugri, borderRadius: 4 }}
          >
            <CardContent sx={{ pb: 0.2 }}>
              <Typography
                sx={{
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "14px",
                }}
              >
                apr (360d)
              </Typography>
            </CardContent>
            <CardContent sx={{ py: 0 }}>
              <Typography
                sx={{
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                18.00%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card
            sx={{ height: "90px", backgroundColor: koyugri, borderRadius: 4 }}
          >
            <CardContent sx={{ pb: 0.2 }}>
              <Typography
                sx={{
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "14px",
                }}
              >
                deposit fee
              </Typography>{" "}
            </CardContent>
            <CardContent sx={{ py: 0 }}>
              <Typography
                sx={{
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                0%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DetailText />
    </Box>
  );
};

export default DetailOne;
