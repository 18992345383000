import {
  firebaseAuthVenlyAction,
  getMyMetamaskWalletsAction,
} from "../../services/actions";
import { simpleListFetch } from "../../services/fetchServices";

const { UserActions } = require("../store/UserStore");

const LogoutUser = () => (dispatch) => {
  dispatch(UserActions.logoutUser());
};

const ConnectVenlyUser = (body, wallet, cb) => (dispatch) => {
  dispatch(UserActions.setLoginResponse(wallet));
  dispatch(UserActions.setWalletAddress(wallet[0].address));
  simpleListFetch(
    firebaseAuthVenlyAction(body),
    (res) => {
      console.log("firebaseAuthVenlyAction", res);
      dispatch(UserActions.setToken(res.token));
      window.localStorage.setItem("Authorization", res.token);
      cb?.(res);
    },
    (err) => {
      console.error("firebaseAuthVenlyAction - Err:", err);
    },
    (onLoad) => {}
  );
};

const gauTokenBalance = (gau) => (dispatch) => {
  console.log("gauTokenBalance", gau);
  dispatch(UserActions.setGauInfo(gau));
};

const getMyMetamaskWallets = () => (dispatch) => {
  simpleListFetch(
    getMyMetamaskWalletsAction(),
    (res) => {
      console.log("getMyMetamaskWalletsAction", res);
      dispatch(UserActions.setMetamaskWalls(res));
    },
    (err) => {
      console.error("getMyMetamaskWalletsAction - Err:", err);
    },
    (onLoad) => {}
  );
};

export { LogoutUser, ConnectVenlyUser, gauTokenBalance, getMyMetamaskWallets };
