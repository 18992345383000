import { useAccount, useReadContract, useWriteContract } from "wagmi";
import gauabi from "../ABI/RealGauAbi.json";
import gaucukAbi from "../ABI/GAUABI.json";
import stakeabi from "../ABI/GauStaking.json";
import { useEffect, useMemo } from "react";
import { enqueueSnackbar } from "notistack";
import { simpleListFetch } from "../services/fetchServices";
import { addBalanceAction } from "../services/actions";
import { formatEther } from "viem";

export const GAU_ADDRESS = "0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2";
const GAUCUK_ADDRESS = "0x13Cc22357Ec72c4dea99c5076e36b621b4c1fC8D";
const MAIN_GAU_WALLET = "0x8c0ae7f324aedd9d8986becfafdbae37175df0a0";
export const stakeContractAddress =
  "0x400b8E9736Af60d48Bf256F4789F51177679a56c";

function replaceZeros(amount, string) {
  if (amount.toString().includes(".")) {
    const [whole, decimal = ""] = amount.toString().split(".");
    if (decimal.length > 0)
      return `${whole}${
        string.substring(0, 0) + decimal + string.substring(decimal.length)
      }`;
  }
  return amount + string;
}
export const useWeb3Functions = () => {
  const { address, isConnected } = useAccount();

  const myGauBalance = useReadContract({
    abi: gauabi,
    address: GAU_ADDRESS,
    functionName: "balanceOf",
    args: [address],
  });

  const totalStaked = useReadContract({
    abi: stakeabi,
    address: stakeContractAddress,
    functionName: "totalStaked",
  });

  const stakedAmountByUser = useReadContract({
    abi: stakeabi,
    address: stakeContractAddress,
    functionName: "stakedAmountByUser",
    args: [address],
  });
  const getClaimableByUser = useReadContract({
    abi: stakeabi,
    address: stakeContractAddress,
    functionName: "getClaimable",
    args: [address],
  });

  const getUnStakeableByUser = useReadContract({
    abi: stakeabi,
    address: stakeContractAddress,
    functionName: "getUnStakeable",
    args: [address],
  });
  const allowanceValue = useReadContract({
    abi: gauabi,
    address: GAU_ADDRESS,
    functionName: "allowance",
    args: [address, stakeContractAddress],
  });

  const getMyGauBalance = useMemo(() => {
    if (isConnected && myGauBalance.data) {
      return Number(formatEther(myGauBalance.data)).toFixed(2);
    } else return "0";
  }, [isConnected, myGauBalance.data]);

  function useSendTransaction({ successCb = () => {}, errorCb = () => {} }) {
    const { writeContractAsync } = useWriteContract();
    function sendTransaction(summaryState, wallet) {
      if (!isConnected) {
        return enqueueSnackbar(`Wallet not connected`, {
          variant: "error",
        });
      }
      let summaryStateString = summaryState?.toString() + "000000000000000000";
      writeContractAsync({
        abi: gauabi,
        address: GAU_ADDRESS,
        functionName: "transfer",
        args: [wallet ? wallet : MAIN_GAU_WALLET, summaryStateString],
      })
        .then((success) => {
          successCb?.();
        })
        .catch((err) => {
          if (err.shortMessage) {
            enqueueSnackbar(`${err.shortMessage}`, {
              variant: "error",
            });
          }
          errorCb?.();
        });
    }

    return { sendTransaction };
  }

  const useDoApprove = () => {
    const { writeContract, isError, error, isSuccess } = useWriteContract();
    const { address } = useAccount();
    function doApprove(amount) {
      let stakeNumber = replaceZeros(amount, "000000000000000001");
      writeContract({
        abi: gauabi,
        address: GAU_ADDRESS,
        functionName: "approve",
        args: [stakeContractAddress, stakeNumber],
      });
    }
    useEffect(() => {
      if (isError) {
        if (error.shortMessage) {
          enqueueSnackbar(`${error.shortMessage}`, {
            variant: "error",
          });
        }
      } else if (isSuccess) {
        enqueueSnackbar("Success", {
          variant: "success",
        });
      }
    }, [isSuccess, isError, address, error]);

    return { doApprove };
  };
  const useStakeTokens = () => {
    const { writeContract, isError, error, isSuccess } = useWriteContract();
    const { address } = useAccount();
    // const gasLimit = 200000; // İşlem için uygun bir gas limit değeri
    // console.log("erro", error, isError, failureReason, isPending, isSuccess);

    function stakeTokens(amount) {
      let stakeNumber = replaceZeros(amount, "000000000000000001");
      writeContract({
        abi: stakeabi,
        address: stakeContractAddress,
        functionName: "stake",
        args: [stakeNumber],
      });
    }
    useEffect(() => {
      if (isError) {
        if (error.shortMessage) {
          enqueueSnackbar(`${error.shortMessage}`, {
            variant: "error",
          });
        }
      } else if (isSuccess) {
        enqueueSnackbar("You have successfully staked your tokens", {
          variant: "success",
        });

        setTimeout(() => {
          (async function () {
            await totalStaked.refetch({
              throwOnError: false,
              cancelRefetch: true,
            });
            await stakedAmountByUser.refetch({
              throwOnError: false,
              cancelRefetch: true,
            });
          })();
        }, 3000);
        if (address) {
          simpleListFetch(
            addBalanceAction({ publicAddress: address, balance: 1 }),
            (res) => {
              // console.log("addBalanceAction", res);
            },
            (err) => {
              console.error("addBalanceAction - Err:", err);
            },
            (onLoad) => {}
          );
        }
      }
    }, [isSuccess, isError, error, address]);

    return { stakeTokens, stakeTokenSuccess: isSuccess };
  };
  const useClaim = () => {
    const { writeContract, isError, error, isSuccess } = useWriteContract();
    function claim() {
      writeContract({
        abi: stakeabi,
        address: stakeContractAddress,
        functionName: "claim",
      });
    }

    useEffect(() => {
      if (isError) {
        if (error.shortMessage) {
          enqueueSnackbar(`${error.shortMessage}`, {
            variant: "error",
          });
        }
      } else if (isSuccess) {
        enqueueSnackbar("Success", {
          variant: "success",
        });
        setTimeout(() => {
          (async function () {
            await getClaimableByUser.refetch({
              throwOnError: false,
              cancelRefetch: true,
            });
          })();
        }, 3000);
      }
    }, [isSuccess, isError, error]);
    return { claim };
  };
  const useUnstake = () => {
    const { writeContract, isError, error, isSuccess } = useWriteContract();
    function unStake() {
      writeContract({
        abi: stakeabi,
        address: stakeContractAddress,
        functionName: "unStake",
      });
    }

    useEffect(() => {
      if (isError) {
        if (error.shortMessage)
          enqueueSnackbar(`${error.shortMessage}`, {
            variant: "error",
          });
      } else if (isSuccess) {
        enqueueSnackbar("Success", {
          variant: "success",
        });
      }
    }, [isSuccess, isError, error]);
    return { unStake };
  };
  return {
    allowanceValue,
    totalStaked,
    stakedAmountByUser,
    getClaimableByUser,
    getUnStakeableByUser,
    address,
    isConnected,
    getMyGauBalance,
    useSendTransaction,
    useDoApprove,
    useStakeTokens,
    useClaim,
    useUnstake,
  };
};
