const getActiveGameAction = (body) => ({
  method: "POST",
  url: "gau/getActiveGame",
  body,
});
const getMyMetamaskWalletsAction = (body) => ({
  method: "GET",
  url: "api/profile/getMyMetamaskWallets",
});

const firebaseAuthVenlyAction = (body) => ({
  method: "POST",
  url: "users/firebaseAuthVenly",
  body,
});

const addMetamaskAction = (body) => ({
  method: "POST",
  url: "api/profile/addMetamask",
  body,
});

const transferWalletAction = (body) => ({
  method: "POST",
  url: "api/web3Premium/transferWallet",
  body,
});
const premiumProcessAction = (body) => ({
  method: "POST",
  url: "api/web3Premium/premiumProcess",
  body,
});

//// FOR AI ///////

const metamaskSignAction = (body) => ({
  method: "POST",
  url: "sign",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});
const addBalanceAction = (body) => ({
  method: "POST",
  url: "addBalance",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});
const sendPromtAction = (body) => ({
  method: "POST",
  url: "sendPromt",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});
const sendPromtAnyAction = (body) => ({
  method: "POST",
  url: "sendPromtAny",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});
const getBalanceAction = (body) => ({
  method: "POST",
  url: "getBalance",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});

const myImagesAction = (body) => ({
  method: "POST",
  url: "myImages",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});
const showRecordsAndPositionAction = (body) => ({
  method: "POST",
  url: "showRecordsAndPosition",
  body,
  urla: "https://ai.api.gamerarenaapp.com/",
});

export {
  getActiveGameAction,
  firebaseAuthVenlyAction,
  getMyMetamaskWalletsAction,
  addMetamaskAction,
  transferWalletAction,
  premiumProcessAction,
  metamaskSignAction,
  addBalanceAction,
  sendPromtAction,
  sendPromtAnyAction,
  getBalanceAction,
  myImagesAction,
  showRecordsAndPositionAction,
};
