import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { siyahimsi } from "../config/colors";

export default function SimpleAreaChart({ totalStaked }) {
  const date = new Date();
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", {
    month: "short",
  })}`;

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const formattedYesterday = `${yesterday.getDate()} ${yesterday.toLocaleString(
    "default",
    { month: "short" }
  )}`;

  const uData =
    totalStaked === 0 ? [3330, 30000000] : [3330, Number(totalStaked) - 3330];
  const xLabels = [formattedYesterday, formattedDate];

  return (
    <LineChart
      height={300}
      series={[{ data: uData, label: "GAU", area: true, showMark: false }]}
      xAxis={[{ scaleType: "point", data: xLabels }]}
      sx={{
        ".MuiLineElement-root": {
          display: "none",
        },
        ".MuiChartsLegend-root": {
          display: "none",
        },
        ".MuiChartsAxis-root": {
          display: "none",
        },
        ".MuiAreaElement-root": {
          fill: siyahimsi, // ya da istediğiniz renk
        },
      }}
    />
  );
}
