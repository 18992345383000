import React, { useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import iconOrange from "../iconOrange.svg";
import {
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { isAndroid, isIOS, isChrome, isMobile } from "react-device-detect";
const ModalOne = ({ open, setOpen }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const clickMetamaskHandler = useMemo(() => {
    if (isMobile) {
      return "https://metamask.app.link/send/0x13Cc22357Ec72c4dea99c5076e36b621b4c1fC8D@43113/transfer?address=0x852771b31fc931ef6602169376f5a559f5279595&uint256=1e18";
    } else if (isChrome)
      return "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
    return "https://metamask.io/download/";
  }, []);

  //   const isMobile = navigator.userAgent.indexOf("Mobile") !== -1;
  // console.log(isMobile);
  // console.log(navigator.userAgent);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {" "}
        <Box
          sx={{
            width: matches ? "560px" : "auto",
            height: "571px",
            backgroundColor: "#262831",
          }}
        >
          <DialogActions sx={{ pr: 2, py: 2 }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </DialogActions>
          <DialogContent
            sx={{ display: "flex", justifyContent: "center", mb: "4%" }}
          >
            <img
              src={iconOrange}
              style={{
                width: "73.33px",
                height: "73.33px",
              }}
            />
          </DialogContent>
          <DialogTitle id="alert-dialog-title">
            <Typography
              sx={{ fontSize: "32px", color: "white", textAlign: "center" }}
            >
              Missing Plug-in
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                color: "#B8B8B9",
                fontSize: "16px",
                textAlign: "center",
                mb: "2%",
                px: "5%",
              }}
            >
              To connect your wallet use web3 compatible browser.You can use
              Google Chrome with Metamask plugin.
            </Typography>
          </DialogContent>
          <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
            <Link target="_blank" underline="none" href={clickMetamaskHandler}>
              <Typography
                sx={{
                  color: "#B8B8B9",
                  fontSize: "14px",
                  textAlign: "center",
                  borderBottom: "1px solid #B8B8B9",
                  display: "inline-block",
                }}
              >
                How to install MetaMask?
              </Typography>
            </Link>
          </DialogContent>
          <DialogActions sx={{ m: "7%", justifyContent: "center" }}>
            <Button onClick={handleClose}>Done</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalOne;
