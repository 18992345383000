import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import metamask from "../metamask.png";
import venly from "../venly_logo.webp";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Metamask from "./Metamask";

const ModalConnect = ({ open, setOpen, connect }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleClose = () => {
    setOpen(false);
  };

  const handleVenlyWallet = () => {
    connect();
    handleClose(); // İşlem yapıldıktan sonra modali kapatmak için
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            width: matches ? "360px" : "auto",
            height: "271px",
            backgroundColor: "#f2f2f2",
          }}
        >
          <DialogActions sx={{ px: 3, py: 2, justifyContent: "space-between" }}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                color: "inherit",
                fontWeight: "bold",
                fontFamily: "Manrope, sans-serif",
              }}
            >
              connect wallet
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Metamask handleClose={handleClose} />
              </Grid>
              {/* <Grid item xs={12}>
                <Box
                  sx={{
                    border: "1px solid #262831",
                    padding: "10px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    "&:hover": {
                      backgroundColor: "#262831",
                      color: "#f2f2f2",
                    },
                  }}
                  onClick={handleVenlyWallet}
                >
                  <img
                    src={venly} // VenlyWallet icon yolunuzu buraya ekleyin
                    alt="VenlyWallet"
                    style={{ width: "20px", marginRight: "5px" }}
                  />
                  <Typography
                    variant="subtitle2"
                    noWrap
                    component="a"
                    // href="#app-bar-with-responsive-menu"
                    sx={{
                      color: "inherit",
                      fontWeight: "bold",
                      fontFamily: "Manrope, sans-serif",
                      textDecoration: "none",
                    }}
                  >
                    venly wallet
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalConnect;
