import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const BoxTwo = () => {
  const [selectedBoxa, setSelectedBox] = useState("USD");

  const handleBoxClick = (box) => {
    setSelectedBox(box);
  };

  return (
    <Box
      sx={{
        border: "1px solid grey",
        borderRadius: 4,
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "43px",
      }}
    >
      <Box
        onClick={() => handleBoxClick("USD")}
        sx={{
          border: "1px",
          borderRadius: 4,
          // backgroundColor: selectedBox === "USD" ? "grey" : "transparent",
          // backgroundColor: selectedBox === "USD" ? "grey" : "transparent",
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          width: "55px",
          height: "40px",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontFamily: "Manrope, sans-serif",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: "1%",
          }}
        >
          7D
        </Typography>
      </Box>
      {/* <Box
        onClick={() => handleBoxClick('30D')}
        sx={{
          border: '1px',
          borderRadius: 4,
          backgroundColor: selectedBox === '30D' ? 'grey' : 'transparent',
          display: 'flex',
          justifyContent: 'center',
          width: '55px',
          height: '40px',
          cursor: 'pointer'
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Manrope, sans-serif',
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: '1%',
          }}
        >
          30D
        </Typography>
      </Box>
      <Box
        onClick={() => handleBoxClick('90D')}
        sx={{
          border: '1px',
          borderRadius: 4,
          backgroundColor: selectedBox === '90D' ? 'grey' : 'transparent',
          display: 'flex',
          justifyContent: 'center',
          width: '55px',
          height: '40px',
          cursor: 'pointer'
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Manrope, sans-serif',
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: '1%',
          }}
        >
          90D
        </Typography>
      </Box>
      <Box
        onClick={() => handleBoxClick('180D')}
        sx={{
          border: '1px',
          borderRadius: 4,
          backgroundColor: selectedBox === '180D' ? 'grey' : 'transparent',
          display: 'flex',
          justifyContent: 'center',
          width: '55px',
          height: '40px',
          cursor: 'pointer'
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Manrope, sans-serif',
            fontSize: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            px: '1%',
          }}
        >
          180D
        </Typography>
      </Box> */}
    </Box>
  );
};

export default BoxTwo;
