import { getActiveGameAction } from "../../services/actions";
import { simpleListFetch } from "../../services/fetchServices";
import { SetupActions } from "../store/SetupStore";

const getActiveGame = (body) => (dispatch) => {
  simpleListFetch(
    getActiveGameAction(body),
    (res) => {
      // console.log("getActiveGameAction", res);
      dispatch(SetupActions.updateGameInfo(res));
    },
    (err) => {
      console.error("getActiveGameAction - Err:", err);
    },
    (onLoad) => {}
  );
};

export { getActiveGame };
