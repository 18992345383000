import {
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { beyaz } from "../../config/colors";
const FooterThree = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Grid
      container
      direction="column"
      alignItems={matches ? "flex-start" : "center"}
      spacing={1}
      sx={{ p: 0, width: "100%" }}
    >
      <Grid item>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "bold",
            fontSize: "16px",
            color: beyaz,
          }}
        >
          exchanges
        </Typography>
      </Grid>
      <Grid item sx={{ py: 0 }}>
        <List sx={{ py: 0 }}>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick(
                  "https://traderjoexyz.com/avalanche/trade?outputCurrency=0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2"
                )
              }
            >
              TraderJoe
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick("https://www.icrypex.com/tr/trade/spot/GAUTRY")
              }
            >
              Icrypex
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick(
                  "https://ascendex.com/tr/cashtrade-spottrading/usdt/gau"
                )
              }
            >
              AscendEX
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick(
                  "https://changelly.com/?from=usdt20&to=gau&amount=100&utm_medium=social&utm_source=twitter&utm_campaign=listing_not"
                )
              }
            >
              changelly
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() => handleLinkClick("https://hitbtc.com/gau-to-usdt")}
            >
              HitBTC
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick("https://www.bitunix.com/spot-trade/GAUUSDT")
              }
            >
              Bitunix
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick("https://www.mexc.com/exchange/GAU_USDT")
              }
            >
              MEXC
            </Typography>
          </ListItem>
          {/* <ListItem sx={{px:0,  justifyContent: !matches &&"center"}}><Typography sx={{fontFamily: "Manrope, sans-serif",
                  fontWeight: "medium",
                  fontSize: "14px",
                  color: beyaz}}>Gate.io</Typography></ListItem> */}
        </List>
      </Grid>
    </Grid>
  );
};

export default FooterThree;
