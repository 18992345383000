import React from "react";
import { siyah } from "../config/colors";
import { Grid } from "@mui/material";
import TotalStakeComp from "../components/TotalStakeComp";
import StakeChart from "../components/StakeChart";
import DetailOne from "../components/DetailOne";
import DetailTwo from "../components/DetailTwo";
import { useWeb3Functions } from "../hooks/useWeb3Functions";
import { formatEther } from "viem";

const Stake = () => {
  const { totalStaked, stakedAmountByUser, useStakeTokens } =
    useWeb3Functions();
  const { stakeTokens } = useStakeTokens();
  const staked = totalStaked.data ? formatEther(totalStaked.data) : "";
  return (
    <div id="stake" style={{ backgroundColor: siyah, height: "100%" }}>
      <TotalStakeComp totalStaked={staked} />
      <StakeChart totalStaked={staked} />
      <Grid container direction="row" sx={{ px: "4%", py: "1%" }}>
        <Grid item sm={7} xs={12}>
          <DetailOne totalStaked={staked} />
        </Grid>
        <Grid item sm={5} xs={12}>
          <DetailTwo
            stakeTokens={stakeTokens}
            stakedAmountByUser={stakedAmountByUser}
          />
        </Grid>
      </Grid>
      {/* <StakeCard /> */}
    </div>
  );
};

export default Stake;
