import { createSlice } from "@reduxjs/toolkit";

const SetupStore = createSlice({
  name: "setup_store",
  initialState: {
    gameInfo: null,
    modalState: false, //bunun yüzünden sürekli tetikleniyor app.jsteki open state'ini buna taşı
  },
  reducers: {
    updateGameInfo: (state, action) => {
      state.gameInfo = { ...state.gameInfo, ...action.payload };
    },
    setModalState: (state, action) => {
      state.modalState = action.payload;
    },
  },
});
export const SetupActions = SetupStore.actions;
export default SetupStore.reducer;
