import { API_URL } from "../config/default";

const defaultHeaders = () => {
  const token = window.localStorage.getItem("Authorization");
  return {
    ...(token && { "x-access-token": token }),
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
};

const handleErrorResponse = async (
  response,
  onError = () => {},
  onLoading = () => {}
) => {
  const resText = await response.text();
  const status = await response.status;
  const statusText = await response.statusText;
  if (resText.length > 0) {
    // console.log('text', resText);
    // try {
    const resData = JSON.parse(resText);
    onError && onError({ ...resData, status, statusText });
    // } catch (e) {
    //   console.log(e);
    //   // onError && onError({...resData, status, statusText});
    // }
  } else {
    onError && onError({});
  }
  onLoading && onLoading(false);
};

const doFetch = async ({
  urla,
  method,
  url,
  urlParamsStr,
  headers,
  onSuccess,
  onError,
  onLoading,
  body,
}) => {
  const aUrl = urla
    ? urla + url + (urlParamsStr ? `?${urlParamsStr}` : "")
    : API_URL + url + (urlParamsStr ? `?${urlParamsStr}` : "");

  try {
    onLoading && onLoading(true);
    const fetchParams = {
      method,
      headers: {
        ...defaultHeaders(),
        ...headers,
      },
      redirect: "follow",
    };
    if (body) {
      if (
        (headers &&
          Object?.values?.(headers)?.includes("multipart/form-data")) ||
        typeof body === "string"
      ) {
        fetchParams.body = body;
      } else {
        fetchParams.body = JSON.stringify(body);
      }
    }

    const response = await fetch(aUrl, fetchParams);
    if (response.ok) {
      const resText = await response.text();
      if (resText.length > 0) {
        const resData = JSON.parse(resText);
        onSuccess && (await onSuccess(resData));
        onLoading && onLoading(false);
        return resData;
      }
      onSuccess({});
      return {};
    }
    handleErrorResponse(response, onError, onLoading);
    return response;
  } catch (e) {
    onLoading(false);
    onError && onError(e);
    return e;
  }
};

const simpleListFetch = async (actionParams, onSuccess, onError, onLoading) =>
  await doFetch({ ...actionParams, onSuccess, onError, onLoading });

export { doFetch, simpleListFetch };
