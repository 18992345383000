import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { beyaz, gri } from "../config/colors";

const BoxOne = () => {
  const [selectedBox, setSelectedBox] = useState("GAU");

  const handleBoxClick = (box) => {
    setSelectedBox(box);
  };
  return (
    <Box
      sx={{
        border: "1px solid grey",
        borderRadius: 4,
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "115px",
        height: "43px",
      }}
    >
      {/* <Box
        onClick={() => handleBoxClick("USD")}
        sx={{
          border: "1px",
          borderRadius: 4,
          backgroundColor: selectedBox === "USD" ? "grey" : "transparent",
          display: "flex",
          justifyContent: "center",
          width: "55px",
          height: "40px",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontFamily: "Manrope, sans-serif",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: "1%",
          }}
        >
          USD
        </Typography>
      </Box> */}
      <Box
        onClick={() => handleBoxClick("GAU")}
        sx={{
          border: "1px",
          borderRadius: 4,
          backgroundColor: "transparent",
          // backgroundColor: selectedBox === "GAU" ? "grey" : "transparent",
          display: "flex",
          justifyContent: "center",
          width: "55px",
          height: "40px",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontFamily: "Manrope, sans-serif",
            fontSize: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            px: "1%",
          }}
        >
          GAU
        </Typography>
      </Box>
    </Box>
  );
};

export default BoxOne;
