import {
  Box,
  Button,
  Grid,
  InputAdornment,
  List,
  Modal,
  TextField,
  Typography,
  createStyles,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { beyaz, siyah } from "../config/colors";
import resim20 from "../assets/aipandas/20.png";
import resim16 from "../assets/aipandas/16.png";
import resim17 from "../assets/aipandas/17.png";
import resim19 from "../assets/aipandas/19.png";
import resim10 from "../assets/aipandas/10.png";
import resim21 from "../assets/aipandas/21.png";
import resim23 from "../assets/aipandas/23.png";
import resim24 from "../assets/aipandas/24.png";
import resim22 from "../assets/aipandas/22.png";
import resim123 from "../assets/aipandas/123.png";
import resim234 from "../assets/aipandas/234.png";
import resim456 from "../assets/aipandas/456.png";
import resim567 from "../assets/aipandas/567.png";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";

import { simpleListFetch } from "../services/fetchServices";
import {
  addBalanceAction,
  getBalanceAction,
  myImagesAction,
  sendPromtAction,
  sendPromtAnyAction,
  showRecordsAndPositionAction,
} from "../services/actions";
import { useSelector } from "react-redux";
import { useWeb3Functions } from "../hooks/useWeb3Functions";
import { enqueueSnackbar } from "notistack";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const imageUrls = [
  { img: resim10, desc: "cyberpunk, futuristic, neon" },
  {
    img: resim16,
    desc: "amazing watercolor painting  award winning, ((masterpiece)), ((best quality)), ((detailed)), Unparalleled, ((Enchanting)), ((Impressive)),cyberpunk art, inspired by Dan Mumford, furry art, details and vivid colors, electric wallpaper, beeple and jeremiah ketner, multicolored vector art, intricate, in a painting of a cat, full res psychedelic therapy, trending on ArtStation, ink splatters, pen lines, incredible detail, creative, positive energy, happy, unique, negative space, pure imagination painted by artgerm ,Surreal Harmony, SLPASHTER_3-7000, Gorgeous splash of vibrant paint, colorful paint, Colorsplash",
  },
  {
    img: resim17,
    desc: "movie screenshot of the high tech futuristic martian invader skull mask, heavy armor suit, science fiction, dark, foggy, ultra detailed, hd, 8k,",
  },
  {
    img: resim123,
    desc: "<lora:Dark_Aura_XL_-_Filled_with_miasma:1> dark aura, black, assasin, dynamic angle, 8K, HD, Illustration, official artwork, anime style, wallpaper, official art",
  },
  { img: resim19, desc: "kratos, god of war, warrior" },
  {
    img: resim234,
    desc: "Anime full body portrait of a realistic swordsman red panda holding his weapon in front of him. its is facing the camera with a fierce look on his face. Anime key visual (best quality, HD, ~+~aesthetic~+~:1.2) ",
  },
  { img: resim21, desc: "cutie, cartoon, baby, kawaii" },
  {
    img: resim456,
    desc: "yamer_pixel_fusion, a futuristic red panda, pixel art, made of metal and wires, neural network connections, red blue and orange wires, in front of the matrix, extremely detailed, masterpiece, 8k, 4k, vaporwave, <lora:Pixel_Fusion_LORA:1> ",
  },
  {
    img: resim567,
    desc: "(cybernetic glass spehre, abstract cyberpunk:1.1) , neon glow, by Arkhyp Kuindzhi, masterpiece, close-up shot of a Fluffy Red Panda, inside a Island, Spring, Horror, Steampunk Art, Sunlight, Dark hue, sfw, <lora:picxer_real:0.7> <lora:epiNoiseoffset_v2:0.6>, slate atmosphere, cinematic, dimmed colors, dark shot, muted colors, film grainy, lut, spooky, (intricate details:0.9), (hdr, hyperdetailed:1.2)",
  },
  {
    img: resim24,
    desc: "assassin creed, main character, assassin, sword, dagger, cloak",
  },
];

const ImageWithOverlay = ({ src, alt, title, setOpen, setDesc }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: "240px",
        height: "240px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setOpen(true);
        setDesc(title);
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(5px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              color: beyaz,
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </div>
      )}
    </div>
  );
};
const ImageWithOverlayYourPhoto = ({ item, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  const awsUrl = "https://gameramobile.s3.eu-west-3.amazonaws.com/aiimages/";

  return (
    <Grid
      item
      xs={12}
      sm={2.4}
      sx={{ p: 2, position: "relative" }}
      key={item.id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a
        href={`${awsUrl}${item.pic}`}
        download={`redpanda${index}.jpg`}
        title={`Download ${item.id}`}
      >
        <img
          alt={item.id}
          src={`${awsUrl}${item.pic}`}
          style={{ width: "240px", height: "240px", cursor: "pointer" }}
        />
        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                color: beyaz,
                textAlign: "center",
              }}
            >
              download redpanda {index}
            </Typography>
          </div>
        )}
      </a>
    </Grid>
  );
};

const StableDiffusion = () => {
  const aiWallet = "0xE8113F4833959ACBbA52b55fB73eD7b6420406Db";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { open: openWeb3Modal } = useWeb3Modal();
  const { isConnected, address } = useAccount();

  const { useSendTransaction } = useWeb3Functions();
  const { sendTransaction } = useSendTransaction({
    errorCb: () => {
      enqueueSnackbar(`An error occurred during the operation`, {
        variant: "error",
      });
    },
    successCb: () => {
      addBalance();
      setOpen(false);
      setEdit(false);
      enqueueSnackbar("Congratulations, you have earned 4 AI generate rights", {
        variant: "success",
      });
    },
  });

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [desco, setDesc] = useState("");
  const [prompt, setPrompt] = useState("");
  const [queue, setQueue] = useState(0);
  const [images, setImages] = useState([]);
  const [position, setPosition] = useState({ position: null });

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };
  const [bala, setBala] = useState(0);

  const addBalance = () => {
    if (isConnected) {
      simpleListFetch(
        addBalanceAction({ publicAddress: address, balance: 1 }),
        (res) => {
          // console.log("addBalanceAction", res);
          getBalance();
        },
        (err) => {
          console.error("addBalanceAction - Err:", err);
        },
        (onLoad) => {}
      );
    }
  };
  const getBalance = () => {
    if (isConnected) {
      simpleListFetch(
        getBalanceAction({ publicAddress: address }),
        (res) => {
          // console.log("getBalanceAction", res);
          setBala(res.balance);
        },
        (err) => {
          console.error("getBalanceAction - Err:", err);
        },
        (onLoad) => {}
      );
    }
  };
  const showRecordsAndPosition = () => {
    if (isConnected) {
      simpleListFetch(
        showRecordsAndPositionAction({ publicAddress: address }),
        (res) => {
          setPosition(res);
          // console.log("showRecordsAndPositionAction", res);
        },
        (err) => {
          console.error("showRecordsAndPositionAction - Err:", err);
        },
        (onLoad) => {}
      );
    }
  };
  const myImages = () => {
    if (isConnected) {
      simpleListFetch(
        myImagesAction({ publicAddress: address, skip: 0, limit: 50 }),
        (res) => {
          setImages(res);
          // console.log("myImagesAction", res);
        },
        (err) => {
          console.error("myImagesAction - Err:", err);
        },
        (onLoad) => {}
      );
    }
  };
  const sendPromt = (x) => {
    if (isConnected) {
      simpleListFetch(
        x
          ? sendPromtAnyAction({
              publicAddress: address,
              prompts: prompt,
            })
          : sendPromtAction({
              publicAddress: address,
              prompts: prompt,
            }),
        (res) => {
          setQueue(res.queuePosition);
          // console.log("sendPromtAction", res);
          showRecordsAndPosition();
          getBalance();
        },
        (err) => {
          console.error("sendPromtAction - Err:", err);
        },
        (onLoad) => {}
      );
    }
  };

  useEffect(() => {
    if (isConnected) {
      getBalance();
      myImages();
    } else {
      setImages([]);
      setBala(0);
    }
  }, [isConnected]);

  useEffect(() => {
    if (position.position !== -1) {
      const intervalId = setInterval(() => {
        showRecordsAndPosition();
      }, 10000);

      return () => clearInterval(intervalId);
    } else {
      myImages();
    }
  }, [position]);

  const handleShare = () => {
    console.log("Share button clicked");
  };

  const handleGenerate = (x) => {
    if (prompt.length > 0) {
      if (bala === 0) {
        sendTransaction(1, aiWallet);
      } else {
        sendPromt(x);
      }
    }
  };
  const handleGenerate2 = () => {
    if (!edit) setPrompt((prev) => desco + prev);
    else if (!prompt.length > 0) setPrompt(desco);
    if (prompt.length > 0) {
      if (bala === 0) {
        sendTransaction(1, aiWallet);
      } else {
        sendPromt();
        setOpen(false);
        setEdit(false);
      }
    }
  };

  const handleEditPrompts = () => {
    setEdit(true);
  };

  // console.log("porp", prompt);

  return (
    <>
      <Grid container sx={{ bgcolor: siyah }}>
        <Grid
          item
          xs={12}
          justifyContent="center"
          textAlign="center"
          flexDirection="row"
          display="flex"
          spacing={3}
          sx={{ pt: 8 }}
        >
          <Box>
            <TextField
              label=""
              id="outlined-start-adornment"
              multiline
              autoFocus
              onChange={(e) => setPrompt(e.target.value)}
              sx={{
                width: isMobile ? "250px" : "900px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "7px",
                  height: 50,
                  border: "1px solid #909090",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="body1" color={beyaz}>
                      {isMobile ? (
                        "prompt"
                      ) : (
                        <>
                          <Typography variant="body1" style={{ color: beyaz }}>
                            enter your prompt here:
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </InputAdornment>
                ),
                style: {
                  color: beyaz,
                  borderColor: beyaz,
                  "& label.Mui-focused": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                },
              }}
            />
            <Typography
              style={{ color: beyaz, fontSize: "12px", lineHeight: "22px" }}
            >
              If you want to generate an image without red panda, you can do it
              with the generate image button
            </Typography>
            <Typography
              style={{ color: beyaz, fontSize: "12px", lineHeight: "22px" }}
            >
              for 1 GAU you get 4 generate rights
            </Typography>
            <Typography
              style={{ color: beyaz, fontSize: "12px", lineHeight: "22px" }}
            >
              {bala === 0 ? "" : `remaining generate: ${bala}`}
            </Typography>
          </Box>

          <Button
            onClick={() => {
              if (isConnected) handleGenerate(0);
              else openWeb3Modal(true);
            }}
            variant="contained"
            size="large"
            sx={{
              backgroundColor: beyaz,
              color: siyah,
              textTransform: "lowercase",
              ml: 1,
              height: 50,
            }}
          >
            generate red panda
          </Button>
          <Button
            onClick={() => {
              if (isConnected) handleGenerate(1);
              else openWeb3Modal(true);
            }}
            variant="contained"
            size="large"
            sx={{
              backgroundColor: beyaz,
              color: siyah,
              textTransform: "lowercase",
              ml: 1,
              height: 50,
            }}
          >
            generate image
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          sx={{ pt: 8 }}
        >
          {position?.position !== -1 && position.position && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "medium",
                  fontSize: "16px",
                  color: beyaz,
                  textAlign: "center",
                  pt: !isMobile && 2,
                }}
              >
                your queue position: {queue}, image generating, please wait...
              </Typography>
            </Grid>
          )}
          {images?.map((item, index) => (
            <ImageWithOverlayYourPhoto item={item} index={index} />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "medium",
              fontSize: "24px",
              color: beyaz,
              textAlign: "center",
              pt: !isMobile && 2,
            }}
          >
            or
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "medium",
              fontSize: "32px",
              color: beyaz,
              textAlign: "center",
              pb: !isMobile && 4,
            }}
          >
            generate your own{" "}
            <strong style={{ color: "#b01f07" }}>red panda</strong> using
            community-created from the selection below
          </Typography>
        </Grid>
        {imageUrls.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={2.4}
            justifyContent="center"
            alignItems="center"
            display="flex"
            sx={{ pt: isMobile && "2%", p: !isMobile && 2 }}
          >
            <ImageWithOverlay
              src={item.img}
              alt={`redpanda-${index}`}
              title={item.desc}
              setOpen={setOpen}
              setDesc={setDesc}
            />
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
            width: isMobile ? "250px" : "750px",
          }}
        >
          {edit ? (
            <TextField
              id="standard-basic"
              label=""
              variant="standard"
              fullWidth
              defaultValue={desco}
              onChange={(e) => setPrompt(e.target.value)}
            />
          ) : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {desco}
              <TextField
                id="standard-basic"
                label=""
                variant="standard"
                fullWidth
                onChange={(e) => setPrompt(e.target.value)}
              />
            </Typography>
          )}

          <Grid container justifyContent="space-between" sx={{ marginTop: 2 }}>
            <Grid item>
              {!edit && (
                <Button
                  onClick={handleEditPrompts}
                  sx={{
                    color: "black",
                    fontSize: "small",
                    textTransform: "lowercase",
                  }}
                >
                  <EditIcon fontSize="small" sx={{ mr: 1 }} /> edit prompt
                </Button>
              )}
            </Grid>
            <Grid item>
              {/* <Button
                onClick={handleShare}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "small",
                  textTransform: "lowercase",
                  mr: 1,
                }}
              >
                <ShareIcon fontSize="small" /> share
              </Button> */}
              <Button
                onClick={() => {
                  if (isConnected) handleGenerate2();
                  else openWeb3Modal(true);
                }}
                variant="contained"
                sx={{
                  backgroundColor: "#9400D3",
                  color: "white",
                  fontSize: "small",
                  textTransform: "lowercase",
                }}
              >
                generate
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default StableDiffusion;
