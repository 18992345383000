import { createSlice } from "@reduxjs/toolkit";

const UserStore = createSlice({
  name: "user_store",
  initialState: {
    loginResponse: null, //login olduktan sonraki bilgiler
    wallets: [],
    gauInfo: null,
    token: null,
    walletAddress: null,
    metamaskWallets: null,
    metamaskWall: null,
    gauBalance: null,
    metamaskSign: null,
  },
  reducers: {
    updateUserType: (state, action) => {
      state.loginResponse = { ...state.loginResponse, ...action.payload };
    },
    setGauInfo: (state, action) => {
      state.gauInfo = action.payload;
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLoginResponse: (state, action) => {
      state.loginResponse = action.payload;
    },
    setMetamaskWallets: (state, action) => {
      state.metamaskWallets = action.payload;
    },
    setMetamaskSign: (state, action) => {
      state.metamaskSign = action.payload;
    },
    setMetamaskWalls: (state, action) => {
      state.metamaskWall = action.payload;
    },

    logoutUser: (state) => {
      state.loginResponse = null;
      state.balance = null;
      state.token = null;
      state.wallets = [];
      state.metamaskWallets = null;
      state.metamaskWall = null;
      state.metamaskSign = null;
    },
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setGauBalance: (state, action) => {
      state.gauBalance = action.payload;
    },
  },
});
export const UserActions = UserStore.actions;
export default UserStore.reducer;
