import { useEffect, useRef, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import Main from "./pages/Main";
import { VenlyConnect } from "@venly/connect";
import { Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import {
  walletConnect,
  metaMask,
  injected,
  coinbaseWallet,
} from "wagmi/connectors";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";

import { WagmiProvider, createConfig, http, useAccount } from "wagmi";
import { avalanche } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  ConnectVenlyUser,
  gauTokenBalance,
  getMyMetamaskWallets,
} from "./redux/actions/UserAction";
import { getActiveGame } from "./redux/actions/SetupAction";
import { persistor, store } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import Stake from "./pages/Stake";
import StakeCardDetail from "./pages/StakeCardDetail";
import { beyaz, siyah } from "./config/colors";

import AIComp from "./components/AIComp";
import { useWeb3Functions } from "./hooks/useWeb3Functions";
import { SnackbarProvider } from "notistack";
import StableDiffusion from "./pages/StableDiffusion";
import { UserActions } from "./redux/store/UserStore";
import { SetupActions } from "./redux/store/SetupStore";

export const projectId = process.env.REACT_APP_WC_PROJECT_ID;
const queryClient = new QueryClient();
const avax = {
  name: "Avalanche C-Chain",
  rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  chainId: `0x${Number(43114).toString(16)}`,
  currency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  explorerUrl: "https://www.avax.network/",
};
const avaxFuji = {
  name: "Fuji Testnet",
  rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  chainId: `0x${Number(43113).toString(16)}`,
  currency: { name: "Avalanche", symbol: "AVAX", decimals: 18 },
  explorerUrl: ["https://cchain.explorer.avax-test.network"],
};
const metadata = {
  name: "gaulaxy labs",
  description:
    "gaulaxy labs is an explore a fusion of gaming and digital currencies",
  url: "https://gaulaxy.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const chains = [avalanche];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

// const config = createConfig({
//   chains: [avalanche],
//   transports: {
//     [avalanche.id]: http(),
//   },
//   connectors: [
//     walletConnect({ projectId, metadata, showQrModal: false }),
//     // injected({ shimDisconnect: true, target: "metaMask" }),
//   ],
// });
// 3. Configure modal ethereum client
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  defaultChain: avalanche,
  allowUnsupportedChain: false,
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
  ],
  themeVariables: {
    "--w3m-z-index": 999999,
    // "--w3m-color-mix-strength": 40,
  },
});

const clientId = "a68d579d-be61-4ebe-9f85-577c64250899";
// const clientIdReal = "e93b618a-2e2f-4f9b-87a4-0496cf4c9d96";
const gauAddress = "0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2";
// const gaucukAddress = "0x13Cc22357Ec72c4dea99c5076e36b621b4c1fC8D";

// const venlyConnect = new VenlyConnect('Testaccount');

// const venlyConnect = new VenlyConnect(clientIdReal);
const venlyConnect = new VenlyConnect(clientId, { environment: "sandbox" });
const chain = "AVAC";

export function AppContextWrapper() {
  const dispatch = useDispatch();
  // activeGameId, gameListId ve score bilgileri var içinde oyuna göndermek için kullanılacak
  const gameInfo = useSelector((state) => state.SetupReducer.gameInfo);
  const { useSendTransaction } = useWeb3Functions();
  const { sendTransaction } = useSendTransaction({});
  const gauInfo = useSelector((state) => state.UserReducer.gauInfo);
  const metamaskSign = useSelector((state) => state.UserReducer.metamaskSign);
  const modalState = useSelector((state) => state.SetupReducer.modalState);
  const { address, isConnected } = useAccount();

  async function connect() {
    try {
      const account = await venlyConnect.flows.getAccount(chain);
      // console.log("auth", account);

      const wallets = await venlyConnect.api.getWallets();

      // kullanıcının token'i ile bizim backend'e istek at ayrıca avac cüzdan bilgilerini wallets'a kaydet
      dispatch(
        ConnectVenlyUser(
          {
            device: 3,
            token: account.auth.token,
            wallet: account.wallets,
          },
          account.wallets
        )
      );

      const aliveli = await venlyConnect.api.getTokenBalanceByAddress(
        chain,
        account.wallets[0].address,
        gauAddress
      );

      dispatch(gauTokenBalance(aliveli));

      if (address) dispatch(getActiveGame({ walletId: address }));
      // else dispatch(getActiveGame({ walletId: address }));

      dispatch(getMyMetamaskWallets());
    } catch (error) {
      console.log(error);
    }
  }

  async function createTransferTokenFunction(walletId, chain) {
    const signer = venlyConnect.createSigner();
    console.log(signer);
    try {
      // const rawTxn = await signer.executeTransfer({
      //   walletId: walletId,
      //   to: "0xb40472a8e675b8992B1A567840897C7e24Df0e53",
      //   value: 1,
      //   secretType: chain,
      //   data: ":)",
      // });
      // console.log("Transaction sent:", rawTxn);

      //GAUCUK

      // const rawTxn = await signer.executeTokenTransfer({
      //   walletId: walletId,
      //   to: "0x16ab7569a98F1b1a3931a8CEd00CB4EeBd059936",
      //   value: 10,
      //   tokenAddress: "0x13Cc22357Ec72c4dea99c5076e36b621b4c1fC8D",
      //   secretType: "AVAC",
      // });
      // console.log("ra", rawTxn);

      const rawTxn = await signer.executeTokenTransfer({
        walletId: walletId,
        to: "0x16ab7569a98F1b1a3931a8CEd00CB4EeBd059936",
        value: 10,
        tokenAddress: "0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2",
        secretType: "AVAC",
      });
      console.log("ra", rawTxn);
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const handleOpen = async () => {
    dispatch(SetupActions.setModalState(true));
  };

  async function showWallets(wallets) {
    const ul = document.createElement("ul");
    const walletListDiv = document.getElementById("walletList");

    const li = document.createElement("li");
    const executeBtn = document.createElement("button");
    executeBtn.innerText = `commit`;

    li.style.margin = 0;

    // Butonun stilini inline CSS ile özelleştirme
    executeBtn.style.backgroundColor = "white";
    executeBtn.style.color = "black";
    executeBtn.style.padding = "8px 16px";
    executeBtn.style.border = "none";
    executeBtn.style.borderRadius = "4px";
    executeBtn.style.outline = "none";
    executeBtn.style.cursor = "pointer";
    executeBtn.style.transition = "background-color 0.3s, color 0.3s";

    // Butona hover efekti ekleme
    executeBtn.addEventListener("mouseenter", function () {
      executeBtn.style.backgroundColor = "#f0f0f0";
      executeBtn.style.color = "#333";
    });

    executeBtn.addEventListener("mouseleave", function () {
      executeBtn.style.backgroundColor = "white";
      executeBtn.style.color = "black";
    });

    executeBtn.onclick = async () => {
      await createTransferTokenFunction(wallets, "AVAC");
    };

    li.appendChild(executeBtn);
    ul.appendChild(li);

    walletListDiv.appendChild(ul);

    const body = document.querySelector("h6");
    body.appendChild(ul);
  }

  const handleClose = () => dispatch(SetupActions.setModalState(false));

  const text = {
    mt: 2,
    color: "white",
    display: "block",
    textTransform: "none",
    fontSize: "15px",
    fontWeight: "medium",
    fontFamily: "Manrope, sans-serif",
  };

  const iframeRef = useRef(null);
  const [dogvumuSamet, setdogvumuSamet] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const iframe = iframeRef.current;

      iframe?.contentWindow?.postMessage(
        { key: event.keyCode, sendType: "keydown" },
        "*"
      );
    };

    const handleKeyUp = (event) => {
      const iframe = iframeRef.current;

      iframe?.contentWindow?.postMessage(
        { key: event.keyCode, sendType: "keyup" },
        "*"
      );
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [iframeRef, dogvumuSamet]);

  useEffect(() => {
    if (address) dispatch(getActiveGame({ walletId: address }));
    // else {
    //   async function isConnected() {
    //     const isConnected = await venlyConnect.checkAuthenticated();
    //     if (isConnected) dispatch(getActiveGame({ walletId: address }));
    //   }
    //   isConnected();
    // }
  }, [address]);

  useEffect(() => {
    if (!isConnected && metamaskSign) {
      dispatch(UserActions.setMetamaskSign(false));
    }
  }, [dispatch, isConnected, metamaskSign]);

  return (
    <BrowserRouter>
      <Header
        connect={connect}
        createTransferTokenFunction={createTransferTokenFunction}
        sendTransaction={sendTransaction}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
      <Routes>
        <Route path="" element={<Main connect={connect} />}></Route>
        <Route
          path="game"
          element={
            <div style={{ height: "100vh" }}>
              <iframe
                ref={iframeRef}
                src={`https://ga-esas-gzip.s3.eu-west-3.amazonaws.com/Gaulaxy/UzaydakiVurmaOyunu/index.html?highScore=${gameInfo?.score}&walletId=${address}`}
                title="Gaulaxy Labs"
                width="100%"
                style={{ height: "95vh" }}
                onLoad={() => setdogvumuSamet(true)}
              ></iframe>
            </div>
          }
        ></Route>
        <Route path="stake" element={<Stake />}></Route>
        <Route path="stakeGau" element={<StakeCardDetail />}></Route>
        {/* <Route path="ai" element={<AIComp />}></Route> */}
        <Route path="aigenerate" element={<StableDiffusion />}></Route>

        <Route
          path="shop"
          element={
            <div
              style={{
                height: "100vh",
                background: siyah,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "50px",
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                coming soon...
              </Typography>
            </div>
          }
        ></Route>
      </Routes>

      <Modal
        open={modalState}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: siyah,
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            sx={{
              display: { xs: "none", md: "flex" },
              color: "inherit",
              fontWeight: "bold",
              fontFamily: "Manrope, sans-serif",
              textDecoration: "none",
              color: "white",
            }}
          >
            profile
          </Typography>
          <Divider sx={{ backgroundColor: "white" }} />
          <Typography sx={text}>wallet address: {address}</Typography>
          {/* <Typography sx={text}>balance: {gauInfo?.balance}</Typography> */}

          <Grid container justifyContent="space-between">
            <Typography sx={text}>
              this week's high score: {gameInfo?.score}
            </Typography>
            {address && (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  textTransform: "none",
                  fontSize: "13px",
                  borderRadius: 2,
                  fontWeight: "bold",
                  fontFamily: "Manrope, sans-serif",
                  mt: 1,
                }}
                onClick={() => {
                  sendTransaction(10);
                }}
              >
                commit
              </Button>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Footer />
    </BrowserRouter>
  );
}

export default function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          // variant="default" // Set default variant
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          preventDuplicate
          autoHideDuration={5000}
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppContextWrapper />
            </PersistGate>
          </Provider>
        </SnackbarProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
