import {
  Avatar,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import gaulaxy from "../../assets/gauwhite.png";
import { beyaz } from "../../config/colors";
import TelegramIcon from "@mui/icons-material/Telegram";
import coingecko from "../../assets/coingecko.svg";
import gitbook from "../../assets/gitbook.svg";
import coin from "../../assets/coin.png";
import x from "../../assets/x.svg";
const FooterOne = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Grid
      container
      direction="column"
      alignItems={matches ? "flex-start" : "center"}
      spacing={2}
      sx={{ p: 0, width: "100%" }}
    >
      <Grid item sx={{ p: 0 }}>
        <div
          onClick={() => {
            navigate("/");
          }}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            alt="q"
            src={gaulaxy}
            style={{
              width: "35px",
              height: "35px",
            }}
          />
          <Typography
            variant="subtitle2"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 0.5,
              color: beyaz,
              fontSize: "18px",
              fontFamily: "Manrope, sans-serif",
              textDecoration: "none",
            }}
          >
            gaulaxy
          </Typography>
          <Typography
            variant="subtitle2"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              color: beyaz,
              fontWeight: "bold",
              fontFamily: "Manrope, sans-serif",
              textDecoration: "none",
              fontSize: "18px",
            }}
          >
            labs
          </Typography>
        </div>
      </Grid>
      {/* <Grid item>
         <Typography sx={{fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "14px",
                    color: beyaz, mr:"15%",textAlign:"center"}}>a gateway for gamers to explore the possibilities of digital currencies</Typography>
     </Grid> */}
      <Grid item>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "medium",
            fontSize: "14px",
            color: beyaz,
          }}
        >
          join our official channels
        </Typography>
      </Grid>
      <Grid item>
        <List>
          <ListItem sx={{ p: 0, justifyContent: !matches && "center" }}>
            <Avatar
              sx={{ mr: "15%", cursor: "pointer", background: "transparent" }}
              onClick={() => handleLinkClick("https://twitter.com/GAUtoken")}
            >
              <img
                alt="q"
                src={x}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: 1,
                  cursor: "pointer",
                }}
                onClick={() => handleLinkClick("https://twitter.com/GAUtoken")}
              />
            </Avatar>
            <Avatar
              sx={{ mr: "15%", cursor: "pointer", background: "transparent" }}
              onClick={() => handleLinkClick("https://t.me/GamerArena_GAU")}
            >
              <TelegramIcon />
            </Avatar>
            <Avatar
              sx={{ mr: "15%", background: "transparent" }}
              onClick={() =>
                handleLinkClick(
                  "https://coinmarketcap.com/currencies/gamer-arena/"
                )
              }
            >
              <img
                alt="q"
                src={coingecko}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: 1,
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleLinkClick(
                    "https://www.coingecko.com/en/coins/gamer-arena"
                  )
                }
              />
            </Avatar>
            <Avatar
              sx={{ mr: "15%", background: "transparent" }}
              onClick={() =>
                handleLinkClick(
                  "https://coinmarketcap.com/currencies/gamer-arena/"
                )
              }
            >
              <img
                alt="q"
                src={coin}
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: 1,
                  cursor: "pointer",
                }}
              />
            </Avatar>
            <Avatar
              onClick={() =>
                handleLinkClick("https://whitepaper.gamerarena.com/")
              }
              sx={{ background: "transparent" }}
            >
              <img
                alt="q"
                src={gitbook}
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: 1,
                  cursor: "pointer",
                }}
              />
            </Avatar>
          </ListItem>
        </List>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <List>
          <ListItem sx={{ p: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                pr: "4%",
              }}
            >
              security
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                pr: "4%",
              }}
            >
              developers
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                pr: "4%",
              }}
            >
              privacy
            </Typography>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
              }}
            >
              terms of service
            </Typography>
          </ListItem>
        </List>
      </Grid>
      {matches && (
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "medium",
              fontSize: "14px",
              color: beyaz,
            }}
          >
            © 2024 Gamer Arena
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default FooterOne;
