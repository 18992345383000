import { Box, Typography } from "@mui/material";
import React from "react";
import { beyaz, siyah } from "../config/colors";

const TitleOne = (props) => {
  const {
    title = "",
    fs = "50px",
    clr = beyaz,
    jc = "center",
    fw = "medium",
    bgcolor = siyah,
  } = props;
  return (
    <Box sx={{ justifyContent: jc, display: "flex", bgcolor: bgcolor,pt:"5%" }}>
      <Typography
        sx={{
          fontSize: fs,
          color: clr,
          fontFamily: "Manrope, sans-serif",
          fontWeight: fw,
          textAlign:"center"
        }}
      >
        {" "}
        {title}{" "}
      </Typography>
    </Box>
  );
};

export default TitleOne;
