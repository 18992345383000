import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TitleOne from "./TitleOne";
import { beyaz, siyah, siyahimsi } from "../config/colors";

const FAQ = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqItems = [
    {
      question: "what is gaulaxy labs?",
      answer: "gaulaxy labs is an innovative blockchain technology company focused on creating gaulaxy, a dynamic platform that seamlessly integrates the GAU token. it serves as a gateway for gamers to explore the fusion of gaming and digital currencies.",
    },
    {
      question: "how does gaulaxy enhance the gaming experience?",
      answer: "gaulaxy enriches the gaming experience by curating a selection of GAU-enhanced gaming products and services. the GAU token unlocks exclusive in-game features, rewards, and benefits.",
    },
    {
      question: "is gaulaxy labs only a gaming platform?",
      answer: "gaulaxy labs is not solely a gaming platform. while it does offer a curated selection of GAU-enhanced gaming experiences within the gaulaxy platform, gaulaxy labs is a broader blockchain technology company. it serves as an innovative hub that goes beyond gaming, providing educational resources, tutorials, and interactive experiences to help users explore and understand the transformative potential of digital currencies, particularly the GAU token. additionally, gaulaxy labs engages in strategic partnerships to extend the utility of GAU across various web3 products within the gaming ecosystem and beyond.",
    },
    {
      question: "what is the GAU token, and how is it integrated into gaulaxy?",
      answer: "the GAU token is the native digital currency integrated into gaulaxy. it offers users in-game benefits, exclusive rewards, and unique features, seamlessly enhancing the overall gaming adventure.",
    },
    {
      question: "who is the target audience for gaulaxy?",
      answer: "gaulaxy caters to a diverse audience of gamers, from blockchain enthusiasts to gaming novices. it's a welcoming space for anyone curious about the fusion of gaming and digital currencies.",
    },
  ];

  return (
    <div style={{ backgroundColor: siyah }}>
      <TitleOne title="faqs" fw="bold" />
      <Grid sx={{ pt: 3, pb: 8, px: isMobile ? 3 : 15, px: "4%" }}>
        {faqItems.map((item, index) => (
          <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 16px 32px rgba(32,40,47, 0.5)",
            pt: 0.7,
            backgroundColor: siyah,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: beyaz }} />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              background: siyahimsi,
              borderRadius:1
            }}
          >
            <Typography variant="h6" sx={{ color: beyaz }}>
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ background: siyahimsi,borderRadius:1 }}>
            <Typography sx={{ color: beyaz }}>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
        
        ))}
      </Grid>
    </div>
  );
};

export default FAQ;
