import React from 'react'
import { beyaz, gri, siyah } from '../config/colors'
import { Box, Grid, IconButton, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material'
import DetailOne from '../components/DetailOne'
import DetailTwo from '../components/DetailTwo'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
const StakeCardDetail = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/stake');
  }
  return (
    <div id="stake" style={{ backgroundColor: siyah, height:matches ? "100vh" :"100%" }}>
<Box>
<Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{px:"4%",py:"1%"}}>
    <Grid item sm={12} xs={12} > 
<List>
  <ListItem sx={{p:0}}> <IconButton onClick={handleClick} sx={{p:0}} ><ArrowBackIcon sx={{color:gri,width:"14px",height:"14px"}} /> 
  <Typography sx={{fontSize:"14px", color:gri,fontFamily: "Manrope, sans-serif",pl:1}}>Back</Typography></IconButton>
  </ListItem>
  <ListItem sx={{p:0}}> <Typography sx={{fontSize:"48px", color:beyaz,fontFamily: "Manrope, sans-serif"}}>GAU</Typography></ListItem>
  <ListItem sx={{p:0}}> <Typography sx={{fontSize:"14px", color: gri ,fontFamily: "Manrope, sans-serif"}}>Rewards distributed every few days</Typography></ListItem>
</List>
    </Grid>
   </Grid>
</Box>
      <Grid container direction="row" sx={{px:"4%",py:"1%"}}>
    <Grid item sm={7} xs={12} > <DetailOne/> </Grid>
    <Grid item sm={5} xs={12} > <DetailTwo/> </Grid>

   </Grid>
    </div>
  )
}

export default StakeCardDetail;