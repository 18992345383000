import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { simpleListFetch } from "../services/fetchServices";
import {
  premiumProcessAction,
  transferWalletAction,
} from "../services/actions";
import CloseIcon from "@mui/icons-material/Close";
import { UserActions } from "../redux/store/UserStore";
import { beyaz } from "../config/colors";
import { useWeb3Functions } from "../hooks/useWeb3Functions";

const modalStyle = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  width: 400,
  backgroundColor: beyaz,
  boxShadow: 24,
  padding: 16,
  overflowY: "auto",
  maxHeight: "80vh",
};

const headTitle = {
  my: 2,
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  fontSize: "15px",
  fontWeight: "bold",
  fontFamily: "Manrope, sans-serif",
  justifyContent: "space-between",
};

const subtit = {
  my: 2,
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  fontSize: "15px",
  fontWeight: "normal",
  fontFamily: "Manrope, sans-serif",
  justifyContent: "space-between",
};

const butt = {
  backgroundColor: "black",
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  borderRadius: 2,
  fontWeight: "bold",
  fontFamily: "Manrope, sans-serif",
  ml: 2,
  mt: 2,
};
const butt2 = {
  backgroundColor: "black",
  color: "white",
  textTransform: "none",
  fontSize: "14px",
  borderRadius: 2,
  fontWeight: "bold",
  fontFamily: "Manrope, sans-serif",
  ml: 2,
};

const CustomModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.UserReducer.token);
  const metamaskWall = useSelector((state) => state.UserReducer.metamaskWall);
  const { getMyGauBalance } = useWeb3Functions();
  const [wallet1, setWallet1] = useState("");
  const [wallet2, setWallet2] = useState("");
  const [walletPro, setWalletPro] = useState("");
  const [amountTrans, setAmountTrans] = useState(0);
  const [amountPro, setAmountPro] = useState(0);
  const [referenceCode, setReferenceCode] = useState("");
  const [amount, setAmount] = useState(0);
  const [amount2, setAmount2] = useState(0);

  const { useSendTransaction } = useWeb3Functions();
  const { sendTransaction } = useSendTransaction({});
  useEffect(() => {
    if (getMyGauBalance > 10) {
      setAmount(10);
      setAmount2(10);
    }
  }, [getMyGauBalance]);

  const handleMaxAmount = () => {
    setAmount(getMyGauBalance);
  };

  const handleMaxAmount2 = () => {
    setAmount2(getMyGauBalance);
  };

  const sendTransfer = () => {
    simpleListFetch(
      transferWalletAction(
        JSON.stringify({
          from: wallet1,
          to: wallet2,
          amount: amountTrans,
        })
      ),
      (res) => {
        dispatch(UserActions.setMetamaskWalls(res));
      },
      (err) => {
        console.error("transferWalletAction - Err:", err);
      },
      (onLoad) => {}
    );
  };

  const goPro = () => {
    simpleListFetch(
      premiumProcessAction(
        JSON.stringify({
          referenceCode: referenceCode,
          walletId: walletPro,
          amount: amountPro,
        })
      ),
      (res) => {
        dispatch(UserActions.setMetamaskWalls(res));
      },
      (err) => {
        console.error("premiumProcessAction - Err:", err);
      },
      (onLoad) => {}
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={modalStyle}>
        {/* Send to GamerArena */}
        <Box display="flex" justifyContent="space-between">
          <Typography sx={headTitle}>gau send to gamerarena</Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon sx={{ color: "#999999" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControl sx={{ width: "50%" }}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              error={amount < 10}
            >
              Amount
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="number"
              size="small"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              error={amount < 10}
              label="Amount"
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    aria-label="maxValue"
                    onClick={handleMaxAmount}
                    edge="end"
                    sx={{ color: "black", textTransform: "none" }}
                  >
                    max ({getMyGauBalance})
                  </Button>
                </InputAdornment>
              }
            />
            {amount < 10 && (
              <FormHelperText error={amount < 10}>
                you can send at least 10 gau.
              </FormHelperText>
            )}
          </FormControl>
          <Button
            disabled={amount < 10}
            onClick={() => sendTransaction(amount)}
            variant="contained"
            sx={butt2}
          >
            Submit
          </Button>
        </Box>

        {/* Send to VenlyWallet */}
        <Typography sx={headTitle}>gau send to your venlywallet</Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControl sx={{ width: "50%" }}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              error={amount < 10}
            >
              Amount
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="number"
              size="small"
              value={amount2}
              onChange={(e) => setAmount2(e.target.value)}
              error={amount2 < 10}
              label="Amount"
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    aria-label="maxValue"
                    onClick={handleMaxAmount2}
                    edge="end"
                    sx={{ color: "black", textTransform: "none" }}
                  >
                    max ({getMyGauBalance})
                  </Button>
                </InputAdornment>
              }
            />
            {amount < 10 && (
              <FormHelperText error={amount < 10}>
                you can send at least 10 gau.
              </FormHelperText>
            )}
          </FormControl>
          <Button
            // disabled={!referenceCode}
            onClick={() => sendTransaction(amount2)}
            variant="contained"
            sx={butt2}
          >
            submit
          </Button>
        </Box>
        {metamaskWall?.wallets?.length > 0 && (
          <>
            <Typography sx={headTitle}>gamer arena wallets</Typography>
            {metamaskWall?.wallets?.map((item, index) => (
              <div key={index}>
                <Typography sx={subtit}>
                  Wallet {index + 1}: {item.publicAddress}
                </Typography>
                <Typography sx={subtit}>
                  Wallet {index + 1} Balance: {item?.count?.$numberDecimal}
                </Typography>
              </div>
            ))}
            {/* Transfer section */}
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                {/* Selecting "from" wallet */}
                <FormControl fullWidth size="small">
                  <InputLabel id="from-select-label">From</InputLabel>
                  <Select
                    labelId="from-select-label"
                    id="from-select"
                    value={wallet1}
                    label="From"
                    onChange={(e) => setWallet1(e.target.value)}
                  >
                    {metamaskWall?.wallets?.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        Wallet {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {/* Selecting "to" wallet */}
                <FormControl fullWidth size="small">
                  <InputLabel id="to-select-label">To</InputLabel>
                  <Select
                    labelId="to-select-label"
                    id="to-select"
                    value={wallet2}
                    label="To"
                    onChange={(e) => setWallet2(e.target.value)}
                  >
                    {metamaskWall?.wallets?.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        Wallet {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                {/* Input for transfer amount */}
                <TextField
                  label="Amount"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={amountTrans}
                  onChange={(e) => setAmountTrans(e.target.value)}
                />
                {/* Button to trigger transfer */}
                <Button
                  disabled={!wallet1 && !wallet2 && !amountTrans}
                  onClick={sendTransfer}
                  variant="contained"
                  sx={butt}
                >
                  Transfer
                </Button>
              </Grid>
            </Grid>

            {/* Premium section */}
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="pro-select-label">Wallet</InputLabel>
                  <Select
                    labelId="pro-select-label"
                    id="pro-select"
                    value={walletPro}
                    label="Wallet"
                    onChange={(e) => setWalletPro(e.target.value)}
                  >
                    {metamaskWall?.wallets?.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        Wallet {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Amount"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={amountPro}
                  onChange={(e) => setAmountPro(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Reference Code"
                  variant="outlined"
                  size="small"
                  value={referenceCode}
                  onChange={(e) => setReferenceCode(e.target.value)}
                />
                <Button
                  disabled={
                    Number(
                      metamaskWall?.wallets.filter(
                        (item) =>
                          item._id === walletPro && item?.count?.$numberDecimal
                      )?.[0]?.count?.$numberDecimal
                    ) < 100 &&
                    !referenceCode &&
                    !walletPro
                  }
                  onClick={goPro}
                  variant="contained"
                  sx={butt}
                >
                  Get Pro
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        <div style={{ textAlign: "right", marginTop: "8px" }}>
          <Button onClick={handleClose} variant="contained" sx={butt2}>
            cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
