import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { siyah, beyaz, gri, gunmetal, koyugri } from "../config/colors";
import gifmimi from "../assets/gaulaxygif.gif";
import sliderbg from "../assets/sliderbg2.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonL from "./ButtonL";
import ModalConnect from "./ModalConnect";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
const MainSliderComp = ({
  handleOpen,
  connect,
  createTransferTokenFunction,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const token = useSelector((state) => state.UserReducer.token);
  const { address } = useAccount();
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  const handleOpenUserMenu = () => {
    open(true);
  };
  const [openCon, setOpenCon] = React.useState(false);
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      {address ? (
        <Carousel controls={false} interval={3000} pause="hover">
          <Carousel.Item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                backgroundColor: siyah,
                backgroundImage: `url(${sliderbg})`, // Arka plan resmini burada ekleyin
                backgroundSize: "cover", // İsteğe bağlı olarak resmi boyutlandırabilirsiniz
                backgroundPosition: "center",
              }}
            >
              <List sx={{ overflowY: "auto" }}>
                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "50px",
                      color: beyaz,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    GAU arena
                  </Typography>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {matches ? (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "medium",
                      }}
                    >
                      challenge & earn gau token <br /> join competition <br />
                      play 15+ games & prove yourself
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "medium",
                        textAlign: "center",
                      }}
                    >
                      challenge & earn gau token <br /> join competition <br />
                      play 15+ games <br /> prove yourself
                    </Typography>
                  )}
                </ListItem>

                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: 2,
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mt: 1,
                        mr: 1,
                        ":hover": {
                          backgroundColor: gri,
                        },
                      }}
                      onClick={() =>
                        handleLinkClick(
                          "https://gameramobile.s3.eu-west-3.amazonaws.com/gamePics/gamerarena/gauArena"
                        )
                      }
                    >
                      google apk
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: 2,
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mt: 1,
                        ":hover": {
                          backgroundColor: gri,
                        },
                      }}
                      onClick={() =>
                        handleLinkClick(
                          "https://apps.apple.com/tr/app/gau-arena/id6476374027"
                        )
                      }
                    >
                      app store
                    </Button>
                  </>
                </ListItem>
              </List>
            </Box>
          </Carousel.Item>
          <Carousel.Item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                backgroundColor: siyah,
                // backgroundImage: `url(${bg})`, // Arka plan resmini burada ekleyin
                backgroundSize: "cover", // İsteğe bağlı olarak resmi boyutlandırabilirsiniz
                backgroundPosition: "center",
              }}
            >
              <List sx={{ overflowY: "auto" }}>
                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "50px",
                      color: beyaz,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    gaulaxy labs
                  </Typography>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {matches ? (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "medium",
                      }}
                    >
                      is an innovative hub where users <br /> explore a fusion
                      of gaming and digital currencies, unlocking <br /> the
                      potential of blockchain technology.
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "medium",
                        textAlign: "center",
                      }}
                    >
                      is an innovative hub where users. explore a fusion of
                      gaming and digital currencies, unlocking the potential of
                      blockchain technology.
                    </Typography>
                  )}
                </ListItem>

                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: 2,
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mt: 1,
                        mr: 1,
                        ":hover": {
                          backgroundColor: gri,
                        },
                      }}
                      onClick={() =>
                        handleLinkClick(
                          "https://traderjoexyz.com/avalanche/trade?outputCurrency=0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2"
                        )
                      }
                    >
                      buy $GAU
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: 2,
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mt: 1,
                        ":hover": {
                          backgroundColor: gri,
                        },
                      }}
                      onClick={() => navigate("/game")}
                    >
                      play
                    </Button>
                  </>
                </ListItem>
              </List>
            </Box>
          </Carousel.Item>
          <Carousel.Item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                backgroundColor: siyah,
                backgroundImage: `url(${gifmimi})`, // Arka plan resmini burada ekleyin
                backgroundSize: "cover", // İsteğe bağlı olarak resmi boyutlandırabilirsiniz
                backgroundPosition: "center",
              }}
            >
              <List sx={{ overflowY: "auto" }}>
                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "50px",
                      color: beyaz,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    gaulaxy 137
                  </Typography>
                </ListItem>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {matches ? (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "medium",
                      }}
                    >
                      dive into the heart-pounding universe of GAULAXY 137!
                      <br />
                      engage in weekly tournaments, survive epic battles, and
                      contribute to the salvation of the cosmos. <br /> commit
                      your high scores via profile section and join the
                      adventure.
                      <br />
                      be a hero in the 137th gaulaxy!
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: beyaz,
                        fontFamily: "Manrope, sans-serif",
                        fontWeight: "medium",
                        textAlign: "center",
                      }}
                    >
                      dive into the heart-pounding universe of GAULAXY 137!
                      engage in weekly tournaments, survive epic battles, and
                      contribute to the salvation of the cosmos. commit your
                      high scores via profile section and join the adventure. be
                      a hero in the 137th gaulaxy!
                    </Typography>
                  )}
                </ListItem>

                <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mt: 1,
                        mr: 1,
                        ":hover": {
                          backgroundColor: gri,
                        },
                      }}
                      onClick={() =>
                        handleLinkClick(
                          "https://traderjoexyz.com/avalanche/trade?outputCurrency=0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2"
                        )
                      }
                    >
                      buy $GAU
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: "4px",
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mt: 1,
                        ":hover": {
                          backgroundColor: gri,
                        },
                      }}
                      onClick={() => navigate("/game")}
                    >
                      play
                    </Button>
                  </>
                </ListItem>
              </List>
            </Box>
          </Carousel.Item>
        </Carousel>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            backgroundColor: siyah,
            // backgroundImage: `url(${bg})`, // Arka plan resmini burada ekleyin
            backgroundSize: "cover", // İsteğe bağlı olarak resmi boyutlandırabilirsiniz
            backgroundPosition: "center",
          }}
        >
          <List sx={{ overflowY: "auto" }}>
            <ListItem sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                gaulaxy labs
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {matches ? (
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: beyaz,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                  }}
                >
                  is an innovative hub where users explore <br /> a fusion of
                  gaming and digital currencies, unlocking <br /> the potential
                  of blockchain technology.
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: beyaz,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    textAlign: "center",
                  }}
                >
                  is an innovative hub where users. explore a fusion of gaming
                  and digital currencies, unlocking the potential of blockchain
                  technology.
                </Typography>
              )}
            </ListItem>

            <ListItem sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonL
                onClick={handleOpenUserMenu}
                clr={beyaz}
                textclr={siyah}
              />
            </ListItem>
          </List>
        </Box>
      )}

      {/* {token || metamaskWallets ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            backgroundColor: siyah,
            backgroundImage: `url(${gifmimi})`, // Arka plan resmini burada ekleyin
            backgroundSize: "cover", // İsteğe bağlı olarak resmi boyutlandırabilirsiniz
            backgroundPosition: "center",
          }}
        >
          <List sx={{ overflowY: "auto" }}>
            <ListItem sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                gaulaxy labs
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {matches ? (
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: beyaz,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                  }}
                >
                  is an innovative hub where users <br /> explore a fusion of
                  gaming and digital currencies, unlocking <br /> the potential
                  of blockchain technology.
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: beyaz,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    textAlign: "center",
                  }}
                >
                  is an innovative hub where users explore a fusion of gaming
                  and digital currencies, unlocking the potential of blockchain
                  technology.
                </Typography>
              )}
            </ListItem>

            <ListItem sx={{ display: "flex", justifyContent: "center" }}>
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: beyaz,
                    color: siyah,
                    textTransform: "none",
                    fontSize: "14px",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontFamily: "Manrope, sans-serif",
                    mt: 1,
                    mr: 1,
                    ":hover": {
                      backgroundColor: gri,
                    },
                  }}
                  onClick={() => navigate("/game")}
                >
                  buy $GAU
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: beyaz,
                    color: siyah,
                    textTransform: "none",
                    fontSize: "14px",
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontFamily: "Manrope, sans-serif",
                    mt: 1,
                    ":hover": {
                      backgroundColor: gri,
                    },
                  }}
                  onClick={() => navigate("/game")}
                >
                  play
                </Button>
              </>
            </ListItem>
          </List>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            backgroundColor: siyah,
            // backgroundImage: `url(${bg})`, // Arka plan resmini burada ekleyin
            backgroundSize: "cover", // İsteğe bağlı olarak resmi boyutlandırabilirsiniz
            backgroundPosition: "center",
          }}
        >
          <List sx={{ overflowY: "auto" }}>
            <ListItem sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: "50px",
                  color: beyaz,
                  fontFamily: "Manrope, sans-serif",
                }}
              >
                gaulaxy labs
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {matches ? (
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: beyaz,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                  }}
                >
                  is an innovative hub where users explore <br /> a fusion of
                  gaming and digital currencies, unlocking <br /> the potential
                  of blockchain technology.
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: beyaz,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    textAlign: "center",
                  }}
                >
                  is an innovative hub where users. explore a fusion of gaming
                  and digital currencies, unlocking the potential of blockchain
                  technology.
                </Typography>
              )}
            </ListItem>

            <ListItem sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonL
                onClick={handleOpenUserMenu}
                clr={beyaz}
                textclr={siyah}
              />
            </ListItem>
          </List>
        </Box>
      )} */}
      <ModalConnect open={openCon} setOpen={setOpenCon} connect={connect} />
    </>
  );
};

export default MainSliderComp;
