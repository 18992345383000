import { Button } from "@mui/material";
import React, { useCallback } from "react";

import { beyaz, siyah } from "../config/colors";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

const ButtonStake = (props) => {
  const { isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const {
    onClick,
    value,
    title = isConnected
      ? value === 0
        ? "stake tokens"
        : "unstake tokens"
      : "connect wallet",
    disabled,
  } = props;
  const clickButton = useCallback(() => {
    if (!isConnected) {
      open(true);
    } else {
      onClick();
    }
  }, [isConnected, onClick, open]);

  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={clickButton}
      sx={{
        width: "100%",
        backgroundColor: beyaz,
        color: siyah,
        textTransform: "none",
        fontSize: "16px",
        borderRadius: 4,
        fontWeight: "bold",
        fontFamily: "Manrope, sans-serif",
      }}
    >
      {title}
    </Button>
  );
};

export default ButtonStake;
