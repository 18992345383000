import { Box, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { koyugri, siyahimsi } from "../../config/colors";
import FooterOne from "./FooterOne";
import FooterTwo from "./FooterTwo";
import FooterThree from "./FooterThree";
import FooterFour from "./FooterFour";

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <Grid
        container
        sx={{
          height: matches ? "330px" : "100%",
          backgroundColor: siyahimsi,
          p: "2%",
        }}
        justifyContent="space-between"
      >
        {matches ? (
          <>
            <Grid item sm={5} xs={12}>
              <FooterOne />
            </Grid>
            <Grid item sm={2.3} xs={12}>
              <FooterTwo />
            </Grid>
            <Grid item sm={2.3} xs={12}>
              <FooterThree />
            </Grid>
            <Grid item sm={2.4} xs={12}>
              <FooterFour />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sx={{ py: "2%" }}>
              <FooterOne />
            </Grid>
            <Grid item xs={12} sx={{ py: "5%" }}>
              <FooterTwo />
            </Grid>
            <Grid item xs={12} sx={{ pb: "5%" }}>
              <FooterThree />
            </Grid>
            <Grid item xs={12}>
              <FooterFour />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Footer;
