import React from "react";

import OurTeam from "../components/OurTeam";
import FAQs from "../components/FAQs";
import Partners from "../components/Partners";

import Testimonials from "../components/Testimonials";
// import bg from "../assets/denemebh.png";
import About from "../components/About";
import MainSliderComp from "../components/MainSliderComp";
import AboutCard from "../components/AboutCard";
const Main = ({ connect }) => {
  return (
    <>
      <MainSliderComp connect={connect} />
      <AboutCard />
      <About />
      <Testimonials />
      <OurTeam />
      <Partners />
      <FAQs />
    </>
  );
};

export default Main;
