import {
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { beyaz } from "../../config/colors";

const FooterTwo = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <Grid
      container
      direction="column"
      alignItems={matches ? "flex-start" : "center"}
      spacing={1}
      sx={{ p: 0, width: "100%" }}
    >
      <Grid item>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "bold",
            fontSize: "16px",
            color: beyaz,
          }}
        >
          GAU token
        </Typography>
      </Grid>
      <Grid item sx={{ py: 0 }}>
        <List sx={{ py: 0 }}>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            {" "}
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick(
                  "https://www.coingecko.com/en/coins/gamer-arena"
                )
              }
            >
              coingecko
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
                cursor: "pointer",
              }}
              onClick={() =>
                handleLinkClick(
                  "https://coinmarketcap.com/currencies/gamer-arena/"
                )
              }
            >
              coinmarketcap
            </Typography>
          </ListItem>
        </List>
      </Grid>
      {/* <Grid item>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "bold",
            fontSize: "16px",

            color: beyaz,
          }}
        >
          analytics
        </Typography>
      </Grid>
      <Grid item sx={{ py: 0 }}>
        <List sx={{ py: 0 }}>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            {" "}
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
              }}
            >
              TokenTerminal
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
              }}
            >
              Defi Llama
            </Typography>
          </ListItem>
        </List>
      </Grid> */}
    </Grid>
  );
};

export default FooterTwo;
