import React from "react";
import Paper from "@mui/material/Paper";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import img1 from "../assets/1.png";
import img2 from "../assets/2.png";
import img3 from "../assets/3.png";
import img4 from "../assets/4.png";
import img5 from "../assets/5.png";
import img6 from "../assets/6.png";
import img7 from "../assets/7.png";
import img15 from "../assets/15.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TitleOne from "./TitleOne";
import { beyaz, siyah } from "../config/colors";

export default function OurTeam() {
  const ourTeam = [
    {
      img: img1,
      name: "Kerem Buker",
      title: "Co-Founder",
      link: "https://www.linkedin.com/in/bukerkerem/",
    },
    {
      img: img6,
      name: "Enes Kerem Turan",
      title: "Web3 Developer",
      link: "https://www.linkedin.com/in/eneskeremturan/",
    },
    {
      img: img3,
      name: "Elif Nur Işık",
      title: "Frontend Developer",
      link: "https://www.linkedin.com/in/elifnurisiikk/",
    },
    {
      img: img2,
      name: "I. Beyazıt Karınca",
      title: "CTO",
      link: "https://www.linkedin.com/in/beyazit-karinca/",
    },
    {
      img: img15,
      name: "Bedirhan Aygül",
      title: "Mobile Developer",
      link: "https://www.linkedin.com/in/bedirhan-aygul/",
    },
    {
      img: img4,
      name: "Atilla",
      title: "Business Developer",
      link: "https://www.linkedin.com/in/atilla-günduğ",
    },
    {
      img: img5,
      name: "Aydın",
      title: "Marketing",
      link: "https://www.linkedin.com/in/ayd%C4%B1n-kara-94bb0528b/",
    },
  ];

  const handleLinkedInClick = (link) => {
    // Burada LinkedIn'e yönlendirme işlemini gerçekleştirecek kodu ekleyebilirsiniz.
    window.open(link, "_blank");
  };

  return (
    <div id="team" style={{ backgroundColor: siyah }}>
      <Box sx={{ pt: 7 }}>
        <TitleOne title="our team" fw="bold" />
      </Box>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={5}
        sx={{ pb: 3, pt: 6 }}
      >
        {ourTeam.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Paper
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.5)", // Opacity'li beyaz arka plan
                borderRadius: "20px",
                display: "flex",
                position: "relative",
                boxShadow: "0px 16px 32px rgba(32,40,47, 0.5)", // Gölgelendirme efekti
                "&:hover": {
                  boxShadow: "0px 16px 32px rgba32,40,47, 3)",
                },
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  color: "#fff",
                  "&:hover": {
                    color: "black",
                    boxShadow: "0px 16px 32px rgba(32,40,47, 1)",
                  },
                }}
                onClick={() => handleLinkedInClick(item.link)}
              >
                <LinkedInIcon />
              </IconButton>
              <img
                src={item.img}
                alt={`avatar-${index}`}
                style={{
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
              />
            </Paper>
            <Typography
              sx={{
                mt: 2,
                fontSize: "20px",
                color: beyaz,
                fontFamily: "Manrope, sans-serif",
                fontWeight: "bold",
              }}
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                mt: 1,
                fontSize: "17px",
                color: beyaz,
                fontFamily: "Manrope, sans-serif",
                fontWeight: "regular",
              }}
            >
              {item.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
