import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { beyaz } from "../config/colors";
import { Padding } from "@mui/icons-material";

const DetailText = () => {
  return (
    <Box sx={{ py: "2%" }}>
      <Typography
        sx={{
          fontFamily: "Manrope, sans-serif",
          fontSize: "18px",
          color: beyaz,
          py: 1,
          fontWeight: "bold",
        }}
      >
        stake information
      </Typography>

      <ul style={{ paddingLeft: 0, margin: 0 }}>
        <li style={{ color: beyaz }}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              color: beyaz,
            }}
          >
            begin earning your portion of the $GAU revenue generated after 30
            days.
          </Typography>
        </li>
        <li style={{ color: beyaz }}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              color: beyaz,
            }}
          >
            GAU deposits and withdrawals are free of additional charges.
          </Typography>
        </li>
        <li style={{ color: beyaz }}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              color: beyaz,
            }}
          >
            please note that gas fees may vary based on the utilization of the
            avalanche c-chain network.
          </Typography>
        </li>
        <li style={{ color: beyaz }}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              color: beyaz,
            }}
          >
            the apr (annual percentage rate) metric indicates a fixed
            annual-based return.
          </Typography>
        </li>
        <li style={{ color: beyaz }}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              color: beyaz,
            }}
          >
            this involves a 360 days locked staking contract
          </Typography>
        </li>
        <li style={{ color: beyaz }}>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontSize: "16px",
              color: beyaz,
            }}
          >
            once claimed, GAU can be utilized for all available features.
          </Typography>
        </li>
      </ul>
    </Box>
  );
};

export default DetailText;
