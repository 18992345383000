import React from "react";
import CardOne from "../components/CardOne";
import TimelineIcon from "@mui/icons-material/Timeline";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import { beyaz, siyah } from "../config/colors";
import TitleOne from "./TitleOne";
import ExtensionIcon from "@mui/icons-material/Extension";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
const About = () => {
  return (
    <div id="about">
      <Box>
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: siyah,
          }}
        >
          <ListItem sx={{ justifyContent: "center" }}>
            <TitleOne title="about gaulaxy labs" />
          </ListItem>
          <ListItem>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ px: "4%" }}
            >
              <Grid item>
                <Typography
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "18px",
                    color: beyaz,
                    textAlign: "justify",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    gaulaxy labs proudly presents gaulaxy, your gateway to a
                    gaming universe where innovation meets entertainment. our
                    mission is to demystify blockchain technology for gamers. as
                    a dynamic platform in the digital currency realm, we invite
                    you to not only play but also explore and understand the
                    incredible potential of the GAU token within our vibrant
                    gaming ecosystem. Gaulaxy caters to a diverse audience, from
                    blockchain enthusiasts to gaming novices, offering a journey
                    of discovery within our immersive platform.
                  </div>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "30px",
                    color: beyaz,
                    textAlign: "left",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    a gaming hub like no other
                  </div>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "18px",
                    color: beyaz,
                    textAlign: "justify",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    curated by gaulaxy labs, gaulaxy serves as a gateway to
                    gamer arena and our esteemed partners. seamlessly
                    incorporating the GAU token, we offer a handpicked selection
                    of gaming experiences where the token is at the heart of
                    your gaming adventure. our strategic partnerships with gamer
                    arena and industry leaders enrich gaulaxy’s offerings,
                    ensuring a wide array of gaming products and services.
                    gaulaxy is your ultimate destination for cutting-edge
                    experiences in the gaming and blockchain space.
                  </div>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "30px",
                    color: beyaz,
                    textAlign: "center",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    unlock the power of the GAU token
                  </div>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    mb: 2,
                    mt: 2,
                    fontFamily: "Manrope, sans-serif",
                    fontWeight: "medium",
                    fontSize: "18px",
                    color: beyaz,
                    textAlign: "justify",
                  }}
                >
                  <div style={{ padding: "8px" }}>
                    gaulaxy seamlessly integrates the GAU token, providing users
                    with in-game benefits, exclusive rewards, and unique
                    features. the token enhances your overall gaming experience,
                    creating an ecosystem where digital currencies play a
                    natural role. gaulaxy also includes an educational
                    component, guiding gamers to understand the benefits and
                    applications of digital currencies. through engaging
                    content, tutorials, and interactive experiences, gaulaxy is
                    where gamers play and learn about the exciting possibilities
                    offered by the GAU token and blockchain technology.
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Box>
    </div>
  );
};

export default About;
