import React from "react";
import { Box, Grid, Tooltip } from "@mui/material";

// Örnek olarak varsayılan gri logoları temsil eden bir dizi logosu
import sup2 from "../assets/sup/2.png";
import sup3 from "../assets/sup/3.png";
import sup5 from "../assets/sup/5.png";
import sup6 from "../assets/sup/6.png";
import sup7 from "../assets/sup/7.png";
import TitleOne from "./TitleOne";
import { siyah } from "../config/colors";

const Partners = () => {
  // Ortak logolarının dizisi

  return (
    <div style={{ backgroundColor: siyah }}>
      <TitleOne title="partners" fw="bold" />

      <Grid
        container
        justifyContent="center"
        display="flex"
        textAlign="center"
        sx={{ mt: 4 }}
      >
        <Grid item xs={12} sm={2.4}>
          <img
            src={sup2}
            alt="Şirket Logo 2"
            style={{ margin: "0 10px", maxWidth: "200px" }}
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <img
            src={sup3}
            alt="Şirket Logo 3"
            style={{ margin: "0 10px", maxWidth: "200px" }}
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <img
            src={sup5}
            alt="Şirket Logo 3"
            style={{ margin: "0 10px", maxWidth: "200px" }}
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <img
            src={sup7}
            alt="Şirket Logo 3"
            style={{ margin: "0 10px", maxWidth: "200px" }}
          />
        </Grid>
        <Grid item xs={12} sm={2.4}>
          <img
            src={sup6}
            alt="Şirket Logo 3"
            style={{ margin: "0 10px", maxWidth: "200px" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Partners;
