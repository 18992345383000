import { Button } from "@mui/material";
import React from "react";
import { formatNumber } from "./SingHelpers";
import { useAccount } from "wagmi";

const ButtonL = (props) => {
  const { address } = useAccount();

  const { clr = "white", textclr = "black", onClick } = props;
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor: clr,
        color: textclr,
        textTransform: "none",
        fontSize: "14px",
        borderRadius: "4px",
        fontWeight: "bold",
        fontFamily: "Manrope, sans-serif",
      }}
    >
      {address ? formatNumber(address) : "launch"}
    </Button>
  );
};

export default ButtonL;
