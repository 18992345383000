import { Avatar, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import { beyaz, siyahimsi } from "../config/colors";
const CardOne = (props) => {
  const { icon = <CurrencyBitcoinIcon /> ,texts="",title=""} = props;
  return (
    <Card
      sx={{
        width: "60%",
        minWidth: 350,
        minHeight:280,
        borderRadius: 3,
        boxShadow: "0 8px 16px rgba(32,40,47, 0.5)",
        my: "5%",
        background: siyahimsi,
      }}
    >
      <CardContent>
        <Avatar sx={{ mb: "3%", color: siyahimsi }}>{icon}</Avatar>

        <Typography sx={{ fontSize: "24px", mb: "3%", color: beyaz,textAlign:"center" }}>
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "medium",
            fontSize: "16px",
            color: beyaz,
            textAlign:"center"
          }}
        >
         {texts}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardOne;
