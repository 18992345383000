import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import ascendex from "../ascendex.png";
import icrypex from "../Icrypex.png";
import traderjoe from "../assets/traderjoe.png";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Metamask from "./Metamask";

const ModalBuyGau = ({ open, setOpen }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const handleClose = () => {
    setOpen(false);
  };

  const handleVenlyWallet = () => {
    handleClose(); // İşlem yapıldıktan sonra modali kapatmak için
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            width: matches ? "360px" : "auto",
            height: "371px",
            backgroundColor: "#f2f2f2",
          }}
        >
          <DialogActions sx={{ px: 2, justifyContent: "space-between" }}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                color: "inherit",
                fontWeight: "bold",
                fontFamily: "Manrope, sans-serif",
              }}
            ></Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "1px solid #262831",
                    padding: "10px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "75px",
                  }}
                  onClick={() => {
                    window.open(
                      "https://traderjoexyz.com/avalanche/trade?outputCurrency=0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2",
                      "_blank"
                    );
                  }}
                >
                  <img
                    src={traderjoe} // VenlyWallet icon yolunuzu buraya ekleyin
                    alt="VenlyWallet"
                    style={{ width: "200px", marginRight: "5px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "1px solid #262831",
                    padding: "10px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "75px",
                  }}
                  onClick={() => {
                    window.open(
                      "https://ascendex.com/en/cashtrade-spottrading/usdt/gau",
                      "_blank"
                    );
                  }}
                >
                  <img
                    src={ascendex} // VenlyWallet icon yolunuzu buraya ekleyin
                    alt="VenlyWallet"
                    style={{ width: "200px", marginRight: "5px" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "1px solid #262831",
                    padding: "10px",
                    cursor: "pointer",
                    borderRadius: "8px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={() => {
                    window.open(
                      "https://www.icrypex.com/en/trade/spot/GAUTRY",
                      "_blank"
                    );
                  }}
                >
                  <img
                    src={icrypex}
                    alt="VenlyWallet"
                    style={{ width: "140px", marginRight: "5px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalBuyGau;
