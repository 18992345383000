import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import gaulaxy from "../assets/gauwhite.png";
import ButtonL from "./ButtonL";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Person4Icon from "@mui/icons-material/Person4";
import Logout from "@mui/icons-material/Logout";
import {
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import TokenIcon from "@mui/icons-material/Token";
import metam from "../metamask.png";
import ModalMissingMetamask from "./ModalMissingMetamask";
import ModalSendTransaction from "./ModalSendTransaction";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser } from "../redux/actions/UserAction";
import { useLocation, useNavigate } from "react-router-dom";
import ModalBuyGau from "./ModalBuyGau";
import { beyaz, koyugri, siyah, siyahimsi } from "../config/colors";
import ModalConnect from "./ModalConnect";
import Metamask from "./Metamask";
import Close from "@mui/icons-material/Close";
import { useAccount, useDisconnect } from "wagmi";

const items = ["about", "team", "whitepaper", "buy", "stake", "ai", "shop"];

function Header({
  handleOpen,
  connect,
  createTransferTokenFunction,
  sendTransaction,
}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElUser2, setAnchorElUser2] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const [openCon, setOpenCon] = React.useState(false);
  const [openModal2, setOpenModal2] = React.useState(false);
  const gameInfo = useSelector((state) => state.SetupReducer.gameInfo);

  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.UserReducer.token);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const logout = () => {
    disconnect();
    dispatch(LogoutUser());
    setAnchorElUser(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    if (token || address) {
      setAnchorElUser(event.currentTarget);
    } else {
      setOpenCon(true);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    console.log("wadww", anchorElNav);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser2(null);
    setAnchorElUser(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    backgroundColor: siyah,
  }));

  const [openModal, setOpenModal] = React.useState(false);
  const [openBuy, setOpenBuy] = React.useState(false);

  const navigate = useNavigate();

  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);
  const scrollToSection = (section) => {
    const targetSection = document.getElementById(section);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
    // console.log(`Scroll to section: ${section}`);
  };
  const handleItemClick = (item) => {
    if (item === "whitepaper") {
      window.open("https://whitepaper.gamerarena.com/", "_blank");
      toggleDrawer(false);
    } else if (item === "buy") {
      setOpenBuy(true);
      toggleDrawer(false);
    } else if (item === "stake") {
      navigate("/stake");
      toggleDrawer(false);
    } else if (item === "ai") {
      navigate("/aigenerate");
      toggleDrawer(false);
    } else if (item === "shop") {
      navigate("/shop");
      toggleDrawer(false);
    } else {
      // Check if on the home page, scroll to the section
      if (location.pathname === "/") {
        scrollToSection(item);
      } else {
        // If on another page, navigate to the home page and pass scroll target
        navigate("/", { state: { scrollTarget: item } });
      }
    }
    toggleDrawer(false); // Drawer'ı kapat
    setOpen(false);
  };
  const [hidd, setHidden] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          display: hidd ? "none" : "flex",
          alignItems: "center",
          height: "4vh",
          backgroundColor: koyugri,
          justifyContent: "space-between",
          pl: isMobile ? 2 : "35%", // Added padding to provide space around the text and button
          pr: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? "12px" : "14px",
            color: beyaz,
            fontFamily: "Manrope, sans-serif",
            fontWeight: "medium",
          }}
        >
          <strong style={{ color: beyaz }}>phishing warning:</strong> please
          make sure you’re visiting
          <strong>
            <a
              href="https://www.gaulaxy.com/"
              style={{ color: "white", paddingLeft: 2, paddingRight: 2 }}
            >
              {" "}
              https://www.gaulaxy.com{" "}
            </a>
          </strong>
        </Typography>
        <IconButton onClick={() => setHidden(true)}>
          <Close sx={{ color: beyaz }} />
        </IconButton>
      </Box>

      <AppBar
        position="sticky"
        sx={{
          backgroundColor: siyah,
          top: 0,
          zIndex: 1201,
          boxShadow: scrolled ? "0px 4px 6px -1px rgba(0,0,0,0.1)" : "none",
        }}
      >
        <Grid spacing={2} sx={{ mx: 2 }}>
          <Toolbar disableGutters>
            <div
              onClick={() => {
                navigate("/");
              }}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                // height:"27px",
                // width:"27px"
              }}
            >
              <img
                alt="q"
                src={gaulaxy}
                style={{
                  width: "35px",
                  height: "35px",
                  marginRight: 1,
                }}
              />
              <Typography
                variant="subtitle2"
                noWrap
                component="a"
                sx={{
                  mr: 0.5,
                  color: beyaz,
                  fontSize: "18px",
                  fontFamily: "Manrope, sans-serif",
                  textDecoration: "none",
                }}
              >
                gaulaxy
              </Typography>
              <Typography
                variant="subtitle2"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  color: beyaz,
                  fontWeight: "bold",
                  fontFamily: "Manrope, sans-serif",
                  textDecoration: "none",
                  fontSize: "18px",
                }}
              >
                labs
              </Typography>
            </div>

            {!isMobile ? (
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {[
                    "about",
                    "team",
                    "whitepaper",
                    "buy",
                    "stake",
                    "ai",
                    "shop",
                  ].map((item) => (
                    <Box
                      key={item}
                      onClick={() => {
                        if (item === "whitepaper") {
                          window.open(
                            "https://whitepaper.gamerarena.com/",
                            "_blank"
                          );
                        } else if (item === "buy") {
                          setOpenBuy(true);
                        } else if (item === "stake") {
                          navigate("/stake");
                        } else if (item === "ai") {
                          navigate("/aigenerate");
                        } else if (item === "shop") {
                          navigate("/shop");
                        } else {
                          // Check if on the home page, scroll to the section
                          if (location.pathname === "/") {
                            scrollToSection(item);
                          } else {
                            // If on another page, navigate to the home page and pass scroll target
                            navigate("/", { state: { scrollTarget: item } });
                          }
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          mr: 2,
                          color: "white",
                          fontWeight: "regular",
                          fontFamily: "Manrope, sans-serif",
                          textDecoration: "none",
                          cursor: "pointer",
                          fontSize: "18px",
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {location?.pathname === "/game" && address && (
                  <>
                    <Typography
                      sx={{
                        color: "white",
                        display: "block",
                        textTransform: "none",
                        fontSize: "15px",
                        fontWeight: "medium",
                        fontFamily: "Manrope, sans-serif",
                        mr: 2,
                      }}
                    >
                      this week's high score: {gameInfo?.score}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: beyaz,
                        color: siyah,
                        textTransform: "none",
                        fontSize: "14px",
                        borderRadius: 2,
                        fontWeight: "bold",
                        fontFamily: "Manrope, sans-serif",
                        mr: 2,
                      }}
                      onClick={() => {
                        sendTransaction(10);
                      }}
                    >
                      commit
                    </Button>
                  </>
                )}

                <Box sx={{ flexGrow: 0 }}>
                  <Metamask
                    // onClick={handleOpenUserMenu}
                    setAnchorElUser={setAnchorElUser}
                  />
                  {/* <ButtonL onClick={handleOpenUserMenu} /> */}
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleOpen}>
                      <ListItemIcon>
                        <Person4Icon fontSize="small" />
                      </ListItemIcon>
                      profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        sendTransaction(10);
                      }}
                    >
                      <ListItemIcon>
                        <TokenIcon fontSize="small" />
                      </ListItemIcon>
                      {/* gamerarena & venly */}
                      commit
                    </MenuItem>
                    {!address && (
                      <MenuItem
                        onClick={() => {
                          token ? setOpenModal2(true) : connect();
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={metam}
                            alt="VenlyWallet"
                            style={{ width: "20px", marginRight: "5px" }}
                          />
                        </ListItemIcon>
                        connect wallet
                      </MenuItem>
                    )}

                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      logout
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(!open)}
                >
                  {open ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Box>
            )}
          </Toolbar>
        </Grid>

        <Drawer
          sx={{
            "& .css-1nvnyqx-MuiPaper-root-MuiDrawer-paper": {
              height: "-webkit-fill-available",
              backgroundColor: siyah,
            },
          }}
          anchor="top"
          open={open}
          onClose={() => toggleDrawer(false)}
        >
          <List sx={{ backgroundColor: siyah, width: "100%", pt: "13%" }}>
            {items.map((item, index) => (
              <>
                <ListItem key={item} onClick={() => handleItemClick(item)}>
                  <Typography
                    sx={{
                      mr: 2,
                      color: "white",
                      fontWeight: "regular",
                      fontFamily: "Manrope, sans-serif",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    {item}
                  </Typography>
                </ListItem>
                {index < items.length - 1 && (
                  <Box
                    key={`divider-${index}`}
                    sx={{
                      mx: 2,
                      color: "#212121",
                      borderTop: "1px solid",
                    }}
                  />
                )}
              </>
            ))}

            <Box sx={{ p: "3%" }}>
              <ButtonL />
            </Box>
          </List>
        </Drawer>
      </AppBar>
      <ModalMissingMetamask open={openModal} setOpen={setOpenModal} />
      <ModalBuyGau open={openBuy} setOpen={setOpenBuy} />
      <ModalConnect open={openCon} setOpen={setOpenCon} connect={connect} />

      <ModalSendTransaction
        open={openModal2}
        handleClose={() => setOpenModal2(false)}
      />
    </>
  );
}

export default Header;
