import React, { useCallback, useEffect, useState } from "react";
import { formatNumber } from "./SingHelpers";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import metam from "../metamask.png";

import { UserActions } from "../redux/store/UserStore";
import ModalSendTransaction from "./ModalSendTransaction";
import { beyaz, siyah } from "../config/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LogoutUser } from "../redux/actions/UserAction";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { simpleListFetch } from "../services/fetchServices";
import { metamaskSignAction } from "../services/actions";
import { useNavigate } from "react-router-dom";
import { useDisconnect, useAccount, useSignMessage } from "wagmi";
import { enqueueSnackbar } from "notistack";

function Metamask({ setAnchorElUser }) {
  const dispatch = useDispatch();
  const metamaskSign = useSelector((state) => state.UserReducer.metamaskSign);
  const { signMessage } = useSignMessage();

  const navigate = useNavigate();
  const [response, setResponse] = useState("");
  const [openModal2, setOpenModal2] = useState(false);
  const AvaxChainId = 43114;
  const { open } = useWeb3Modal();
  const { isConnected, chainId, address } = useAccount();
  const { disconnect } = useDisconnect();
  async function onCl(event) {
    // if (metamaskWallets) setAnchorElUser(event.currentTarget);
    if (!isConnected) open(true);
    else setAnchorElUser(event.currentTarget);
  }
  const signStableAI = () => {
    simpleListFetch(
      metamaskSignAction({ publicAddress: address }),
      (res) => {
        // console.log("metamaskSignAction", res);
      },
      (err) => {
        // console.error("metamaskSignAction - Err:", err);
      },
      (onLoad) => {}
    );
  };

  const connectWallet = useCallback(() => {
    if (!metamaskSign) {
      signMessage(
        {
          message: "you are signing in to gaulaxy labs!",
          account: address,
        },
        {
          onSuccess: (res) => {
            dispatch(UserActions.setMetamaskWallets(address));
            signStableAI();
            dispatch(UserActions.setMetamaskSign(res));
          },
          onError: (errSign) => {
            if (errSign.code === 4001) {
              enqueueSnackbar(`You rejected the request in your wallet.`, {
                variant: "error",
              });
            }
            logout();
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, metamaskSign]);

  const logout = () => {
    dispatch(LogoutUser());
    disconnect();
    navigate("/");
  };

  const cache = localStorage.getItem("@w3m/wallet_id");
  useEffect(() => {
    if (!cache && address) {
      logout();
    }
  }, [address, cache]);
  useEffect(() => {
    if (isConnected) {
      connectWallet();
    }
  }, [connectWallet, isConnected]);

  return (
    <div>
      <Box
        sx={{
          border: "1px solid #262831",
          padding: "10px",
          cursor: "pointer",
          borderRadius: "4px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          backgroundColor: beyaz,
          color: siyah,

          "&:hover": {
            backgroundColor: "#262831",
            color: "#f2f2f2",
          },
        }}
        onClick={onCl}
      >
        <img
          src={metam} // VenlyWallet icon yolunuzu buraya ekleyin
          alt="Metamask"
          style={{ width: "20px", marginRight: "5px", marginLeft: "5px" }}
        />
        <Typography
          variant="subtitle2"
          noWrap
          component="a"
          // href="#app-bar-with-responsive-menu"
          sx={{
            color: "inherit",
            fontWeight: "bold",
            fontFamily: "Manrope, sans-serif",
            textDecoration: "none",
          }}
        >
          {address && isConnected ? formatNumber(address) : "connect wallet"}
        </Typography>
        <KeyboardArrowDownIcon fontSize="small" />
      </Box>

      <ModalSendTransaction
        open={openModal2}
        handleClose={() => setOpenModal2(false)}
      />

      {/* <button
        className={"Button-Danger"}
        style={{ padding: 10, margin: 10 }}
        onClick={terminate}
      >
        Terminate
      </button> */}

      {/* <Button
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "black",
            textTransform: "none",
            fontSize: "14px",
            borderRadius: 2,
            fontWeight: "bold",
            fontFamily: "Manrope, sans-serif",
          }}
          onClick={() => setOpenModal2(true)}
        >
          {formatNumber(account)}
        </Button> */}
    </div>
  );
}

export default Metamask;
